import React, { Component } from "react"
import Sunburst from "./Sunburst"
import "../../../helpers/styles/general.scss"
import "./sunburst-container.scss"
import _ from "lodash"
import { log } from "util"

export default class SunburstContainer extends Component {
  state = {
    data: {},
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { data, creativeCycleSelected, dimensionSelected } = nextProps
    if (!data) return {}

    const dataTemplate = {
      id: "IC",
      children: [
        {
          id: "Sociedade",
          active: false,
          children: [{ id: "Consumo Participação", active: false, size: 4 }],
        },
        {
          id: "Mercado",
          active: false,
          children: [
            { id: "Difusão Marketing", active: false, size: 4 },
            { id: "Criação Marketing", active: false, size: 4 },
            { id: "Preservação Marketing", active: false, size: 4 },
          ],
        },
        {
          id: "Estado",
          active: false,
          children: [
            { id: "Gestão Regulação", active: false, size: 4 },
            { id: "Preservação", active: false, size: 4 },
          ],
        },
        {
          id: "Estado Mercado",
          active: false,
          children: [{ id: "Difusão Marketing", active: false, size: 4 }],
        },
      ],
    }

    if (nextProps.tagSelected) {
      var filteredData = nextProps.data.filter(
        row => row.node.tag1 === nextProps.tagSelected
      )
    }

    if (nextProps.indicatorGroupSelected) {
      filteredData = filteredData.filter(
        row => row.node.tagGroup === nextProps.indicatorGroupSelected
      )
    }

    if (nextProps.creativeCycleSelected) {
      filteredData = filteredData.filter(
        row =>
          row.node.cicloCriativo ===
          nextProps.creativeCycleSelected.replace(" ", "/")
      )
    }

    if (nextProps.dimensionSelected) {
      filteredData = filteredData.filter(
        row =>
          row.node.dimensao === nextProps.dimensionSelected.replace(" ", "/")
      )
    }

    _.map(dataTemplate.children, d => {
      d["id"] = d["id"].replace(" ", "/")

      var dimensionActive = _.map(filteredData, j => {
        return j.node.dimensao === d.id
      })

      if (dimensionActive.includes(true)) d.active = true

      d["id"] = d["id"].replace("/", " ")
    })

    _.map(dataTemplate.children, d => {
      _.map(d.children, j => {
        j["id"] = j["id"].replace(" ", "/")

        var creativeCycleActive = _.map(
          filteredData,
          u => u.node.cicloCriativo === j.id
        )

        if (creativeCycleActive.includes(true)) j.active = true
        j["id"] = j["id"].replace("/", " ")
      })
    })

    return { data: dataTemplate }
  }

  render() {
    return (
      <div className="sunburst-chart-wrapper  obcrei-background-1">
        <div
          className="close-sunburst"
          onClick={this.props.toggleCircularMenuIsOpen}>
          <svg width="22px" height="22px" viewBox="0 0 22 22">
            <circle fill="#1E1B2F" fillRule="evenodd" cx="11" cy="11" r="11" />
            <text
              fontFamily="OpenSans-Bold, Open Sans"
              fontSize="14"
              fontWeight="bold"
              letterSpacing="-0.21"
              fill="#FFFFFF">
              <tspan x="7" y="16">
                X
              </tspan>
            </text>
          </svg>
        </div>
        <Sunburst
          data={this.state.data}
          creativeCycleSelected={this.props.creativeCycleSelected}
          dimensionSelected={this.props.dimensionSelected}
          setCreativeCycleSelected={this.props.setCreativeCycleSelected}
          setDimensionSelected={this.props.setDimensionSelected}
        />
        <div
          className="clear-selection typo-body-2-chart"
          onClick={() => {
            this.props.setCreativeCycleSelected(null)
            this.props.setDimensionSelected(null)
            return
          }}>
          limpar
        </div>
      </div>
    )
  }
}
