import React from "react"
import { StaticQuery, graphql } from "gatsby"
import "./simple-text-content-overlay.scss"
import "../../../helpers/styles/typography.scss"
import threeDecideLogo from "../../../data/assets/3decide_contrast_white_logo.svg"

const SimpleTextContentOverlay = ({ setIntroLayerOverlayFalse, textData }) => {
  //const text = data.obcreiEstruturaIndicadoresJson;

  const text = textData.childDashboardContentJson

  return (
    <div className="simple-text-overlay-wrapper">
      <div className="container">
        <div className="row justify-content-md-center">
          <h3 className="col col-sm-10 col-xs-12" style={{ color: "#FFFFFF" }}>
            {text.titleIndicators}
          </h3>
          <div
            className="col col-sm-10 col-xs-12 typo-body-regular-sobre-obcrei"
            dangerouslySetInnerHTML={{
              __html: text.textContentIndicators?.data?.textContentIndicators,
            }}
            style={{ color: "#FFFFFF" }}
          />
          <div
            className="col col-sm-10 col-xs-12"
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "space-between",
            }}>
            <div
              onClick={() => setIntroLayerOverlayFalse()}
              className="btn btn-creialab  text--legend"
              style={{
                minWidth: 146,
                height: 38,
                lineHeight: "25px",
                backgroundColor: "transparent",
                color: "#FFF",
                cursor: "pointer",
              }}>
              {text.openDashboardButton}
            </div>
            <div className="3decide-logo-wrapper">
              <div className="3decide-logo-title typo-body3-regular">
                Engineered by
              </div>
              <div
                className="3decide-logo-image-wrapper"
                style={{
                  width: 90,
                  height: 31,
                }}>
                <img src={threeDecideLogo} />
              </div>
            </div>
          </div>
          <div className="col col-sm-10 col-xs-12 3decide-logo-obcrei" />
        </div>
      </div>
    </div>
  )
}

export default SimpleTextContentOverlay
