import React, { Component } from "react"
import "../../../helpers/styles/general.scss"
import SunburstContainer from "./SunburstContainer"
import './circular-menu.scss'


export default class CircularMenu extends Component {
  render() {
    return (
      <>
        {this.props.circularMenuIsOpen && this.props.tagSelected ? (
          <SunburstContainer
            toggleCircularMenuIsOpen={this.props.toggleCircularMenuIsOpen}
            creativeCycleSelected={this.props.creativeCycleSelected}
            dimensionSelected={this.props.dimensionSelected}
            setCreativeCycleSelected={this.props.setCreativeCycleSelected}
            setDimensionSelected={this.props.setDimensionSelected}
            data={this.props.data}
            tagSelected={this.props.tagSelected}
            indicatorGroupSelected={this.props.indicatorGroupSelected}
          />
        ) : (
          <div className="circular-menu-button-container">
          <button
            onMouseEnter={ () =>this.props.hoverMenuButtons () }
            onMouseLeave={() => this.props.hoverMenuButtons () }
            className="menu-button"
            onClick={() => this.props.toggleCircularMenuIsOpen() && this.props.tagSelected }>
            {this.props.circularMenuButton}
          </button>    
          </div>
        )}
      </>
    )
  }
}
