import React, { Component } from "react"
import Map from "./Map"
import "../../../helpers/styles/general.scss"
import "./map-container.scss"
import { StaticQuery, graphql } from "gatsby"
import { set } from "lodash"

//TODO mudar query para os geosons para tirar os estaticos de baixo

export default class MapData extends Component {
  state = {
    nuts2: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [-8.796321620521173, 37.44294791250656],
                [-8.728537744791819, 37.39852112587652],
                [-8.623394665472171, 37.39944184555831],
                [-8.569198241186918, 37.4108770136335],
                [-8.520332565849321, 37.38155701225655],
                [-8.4564276587529, 37.3749850829678],
                [-8.395186491518297, 37.42571294001689],
                [-8.319907378351589, 37.43686108788143],
                [-8.277569303467306, 37.42464095422601],
                [-8.248529451237996, 37.384628549297965],
                [-8.188484871262839, 37.34160957623999],
                [-8.06584676968331, 37.31893672905617],
                [-8.004860271971665, 37.38631539747137],
                [-7.965254152634997, 37.40901449298766],
                [-7.832300493820128, 37.44857283372562],
                [-7.71299803662831, 37.501776274089444],
                [-7.641476917086726, 37.50015683143589],
                [-7.512458663891316, 37.52629449945119],
                [-7.456210375554193, 37.45018863629096],
                [-7.440476573419271, 37.39101532421058],
                [-7.441099722222586, 37.30730649514952],
                [-7.422044481466808, 37.274851569278844],
                [-7.404364957229204, 37.170750986170496],
                [-7.506191176140689, 37.16759107080952],
                [-7.601539413649471, 37.128009388562575],
                [-7.814599770581366, 36.996107032439674],
                [-7.901245299073844, 36.96336528426251],
                [-7.948070472178702, 36.9773231376231],
                [-8.09555016561552, 37.064075746678704],
                [-8.184747078244028, 37.0905513316266],
                [-8.286607924465532, 37.072743429533155],
                [-8.371120796796976, 37.101654209124916],
                [-8.436777481135902, 37.08533001493058],
                [-8.529500166757359, 37.11567983537223],
                [-8.640095262559207, 37.11873855742335],
                [-8.685742375587836, 37.085342196228936],
                [-8.82578858975193, 37.06439229272715],
                [-8.949126110691605, 37.00829379261069],
                [-8.978633700770711, 37.056056267885566],
                [-8.903384220685536, 37.16544300607765],
                [-8.909373641243048, 37.18937598963247],
                [-8.86020489938705, 37.26949162943123],
                [-8.871818768349971, 37.322003254364986],
                [-8.796321620521173, 37.44294791250656],
              ],
            ],
          },
          properties: { NUTS2: "Algarve" },
        },
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [-7.512458663891316, 37.52629449945119],
                [-7.641476917086726, 37.50015683143589],
                [-7.71299803662831, 37.501776274089444],
                [-7.832300493820128, 37.44857283372562],
                [-7.965254152634997, 37.40901449298766],
                [-8.004860271971665, 37.38631539747137],
                [-8.06584676968331, 37.31893672905617],
                [-8.188484871262839, 37.34160957623999],
                [-8.248529451237996, 37.384628549297965],
                [-8.277569303467306, 37.42464095422601],
                [-8.319907378351589, 37.43686108788143],
                [-8.395186491518297, 37.42571294001689],
                [-8.4564276587529, 37.3749850829678],
                [-8.520332565849321, 37.38155701225655],
                [-8.569198241186918, 37.4108770136335],
                [-8.623394665472171, 37.39944184555831],
                [-8.728537744791819, 37.39852112587652],
                [-8.796321620521173, 37.44294791250656],
                [-8.785668102606763, 37.53279521851557],
                [-8.819276320159874, 37.59843963070621],
                [-8.795452202557815, 37.67561004727595],
                [-8.807137595120674, 37.774805505978335],
                [-8.789948001888497, 37.837639673800446],
                [-8.815417777451739, 37.931287630135316],
                [-8.883879187507848, 37.95004756359345],
                [-8.819485689305852, 38.0619663141067],
                [-8.780582780785386, 38.181964188336565],
                [-8.776450929813988, 38.262152259768314],
                [-8.804726135135354, 38.38276809067481],
                [-8.834519522570213, 38.42929750696997],
                [-8.912867460989997, 38.502497545321134],
                [-8.817892450525745, 38.464244038163486],
                [-8.753693523230647, 38.470557723957405],
                [-8.68161582827363, 38.5424897419183],
                [-8.639113942565166, 38.54949117752527],
                [-8.656531506296082, 38.60781259845035],
                [-8.566213719249047, 38.678179937828126],
                [-8.531214543155455, 38.69186096901616],
                [-8.4909721256268, 38.76131061705447],
                [-8.607219852915756, 38.77736350610597],
                [-8.69692469776532, 38.840390631734145],
                [-8.791581550929555, 38.73412171765559],
                [-8.833540424806372, 38.761111170775834],
                [-8.908341246445646, 38.74180496149228],
                [-8.96699016914478, 38.826141963941424],
                [-8.968466086132135, 38.82776495640026],
                [-8.91188065324177, 38.88767690971293],
                [-8.84714955761442, 39.01089239121599],
                [-8.930276980476998, 39.01802485359567],
                [-8.955667572255695, 39.03960840263154],
                [-8.918695735971635, 39.10605909743707],
                [-8.971194938525851, 39.17876018905836],
                [-9.004548097499592, 39.18727379522175],
                [-8.975224499102813, 39.28713637970193],
                [-9.00154955301769, 39.35476364615499],
                [-8.945698986952188, 39.391064074306584],
                [-8.903429356755314, 39.46600435285939],
                [-8.80231099983147, 39.48300213441998],
                [-8.714875413440463, 39.44146367236231],
                [-8.69662294231443, 39.39909117279636],
                [-8.62551889364995, 39.433509652121465],
                [-8.595279913781866, 39.405395909502005],
                [-8.537248535838746, 39.39713929068026],
                [-8.442475851280854, 39.456849210764275],
                [-8.344522079110632, 39.463789661863764],
                [-8.298296024180381, 39.39908066569199],
                [-8.261499695210013, 39.29215676662591],
                [-8.217208964187316, 39.25293399630623],
                [-8.15138251704885, 39.24956713702404],
                [-8.134468539436767, 39.287400085332344],
                [-8.0885208135434, 39.27517147547068],
                [-7.943168165618731, 39.42022702299831],
                [-8.00629947847759, 39.46181167026155],
                [-8.000142833784789, 39.50493794193581],
                [-7.959617696349625, 39.5610467346725],
                [-7.84774989877656, 39.51070460224476],
                [-7.822352106954303, 39.53991483379681],
                [-7.72230552560275, 39.59326756587843],
                [-7.673108476666275, 39.64890940126349],
                [-7.637442481409233, 39.62272354420859],
                [-7.541680166315928, 39.66367957825821],
                [-7.499407968251364, 39.58960717104199],
                [-7.37714088853758, 39.48924642605382],
                [-7.294261007602349, 39.45682754296764],
                [-7.322854948800315, 39.381952803484616],
                [-7.310341038498378, 39.340960926543254],
                [-7.231471694241703, 39.27825850568684],
                [-7.248018404805054, 39.253375510666174],
                [-7.221607101389341, 39.1932998116419],
                [-7.132222900170709, 39.16453573880126],
                [-7.144024855972424, 39.10863269649866],
                [-7.028190804793474, 39.11565325819428],
                [-6.980903488309268, 39.08782071967703],
                [-6.951130314157039, 39.02359807048164],
                [-7.052181022659306, 38.90695336259495],
                [-7.033021736492887, 38.8788249789387],
                [-7.096372576386986, 38.815966503721775],
                [-7.125404023010635, 38.81532738916861],
                [-7.202783738830153, 38.7520648317311],
                [-7.260033845634311, 38.722942510248046],
                [-7.272498020053357, 38.63840595257624],
                [-7.263905197506062, 38.58786898204048],
                [-7.303360830742494, 38.54310174306768],
                [-7.317897700723123, 38.44008741598935],
                [-7.284333255946806, 38.423178115787955],
                [-7.248174789331525, 38.36951230255234],
                [-7.142943831203078, 38.2617146663855],
                [-7.09722460375829, 38.17895317579036],
                [-7.02859886303, 38.18251471059862],
                [-6.942298747516331, 38.21808941351554],
                [-6.969231114383495, 38.16048274983521],
                [-7.007185941694111, 38.02075197869294],
                [-7.044719216752107, 38.0139943743601],
                [-7.108291160458424, 38.04093370597595],
                [-7.128732968483519, 38.001699010658385],
                [-7.245569374762359, 37.991799230770816],
                [-7.254785311381209, 37.92369601768328],
                [-7.321255857203382, 37.81868601578429],
                [-7.418977276268115, 37.74348478679676],
                [-7.444830539074613, 37.6639327759343],
                [-7.522665146692645, 37.55306520007299],
                [-7.512458663891316, 37.52629449945119],
              ],
            ],
          },
          properties: { NUTS2: "Alentejo" },
        },
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [-8.653119331726854, 40.96477827647259],
                [-8.64417775212388, 41.01781908292695],
                [-8.668025979836557, 41.1419507986743],
                [-8.716368416081696, 41.203165223034645],
                [-8.741381756298608, 41.33659476631176],
                [-8.788907269389842, 41.4166840524834],
                [-8.777397279505161, 41.481343945619614],
                [-8.83059535381902, 41.67781095879089],
                [-8.88105831699491, 41.75269346808333],
                [-8.871529779443623, 41.86390077556161],
                [-8.818381394432846, 41.91128615277701],
                [-8.747727265795529, 41.943141923198596],
                [-8.745968006193355, 41.96454549869689],
                [-8.661358752332776, 42.00331763735916],
                [-8.636780978785309, 42.0474866207606],
                [-8.546836111539417, 42.05398891427905],
                [-8.518053523131467, 42.07955538761205],
                [-8.42894217724946, 42.072286535938794],
                [-8.331890767529124, 42.08383452718637],
                [-8.253635374029635, 42.13668423593164],
                [-8.195388608852012, 42.14819997519456],
                [-8.186149821859695, 42.072585064822015],
                [-8.11870076060627, 42.082306565268006],
                [-8.086034780896856, 42.016492173445364],
                [-8.163019279228305, 41.98269174686339],
                [-8.216899264091124, 41.913154786390145],
                [-8.161772355530784, 41.860975046340194],
                [-8.1650131120599, 41.818113179125234],
                [-8.093917054058435, 41.80803533009123],
                [-8.01260258796392, 41.83353170615205],
                [-7.988667451981915, 41.86751756436568],
                [-7.92130457924723, 41.88181420050975],
                [-7.73335377616781, 41.89227694751503],
                [-7.70414248631645, 41.907339790734774],
                [-7.573911886679392, 41.82971993894492],
                [-7.452499308075502, 41.86508167205012],
                [-7.389964241709365, 41.84230540663632],
                [-7.315315074519633, 41.84254537689551],
                [-7.196737819325854, 41.879979369411515],
                [-7.173649151692826, 41.918919610559136],
                [-7.186659538750194, 41.969433806675],
                [-7.141458767911827, 41.990757774549664],
                [-7.07695584659362, 41.95193726458661],
                [-6.750582410933438, 41.94364551920959],
                [-6.700005809454407, 41.933315058314854],
                [-6.599459047409037, 41.94830277853268],
                [-6.545964674176445, 41.93079220870259],
                [-6.571179417211172, 41.8834954290125],
                [-6.517640132611733, 41.875088173624036],
                [-6.564473648612476, 41.75168287294005],
                [-6.548273747748854, 41.685595327147716],
                [-6.512554859536072, 41.661223693161794],
                [-6.452362909058614, 41.68090973591883],
                [-6.354666627221147, 41.6764052386439],
                [-6.254458242376243, 41.63299941359287],
                [-6.189159307482876, 41.57483180148935],
                [-6.305281967803408, 41.44970972849684],
                [-6.315408635915114, 41.39006858975073],
                [-6.41526868731382, 41.34795850977315],
                [-6.468847959420782, 41.301276187663056],
                [-6.580523323653452, 41.23905822284957],
                [-6.648377518568997, 41.24755030150994],
                [-6.701469641844634, 41.18028137708424],
                [-6.767871220287496, 41.134837080187665],
                [-6.754193816823948, 41.104061669296755],
                [-6.808931005375524, 41.03649648530623],
                [-6.931356647135623, 41.02941112501444],
                [-6.973752423936464, 41.03497264224431],
                [-6.997353749559249, 40.98219975708748],
                [-7.045912274042355, 40.967491023037766],
                [-7.166821904880515, 40.97200904649631],
                [-7.178088566192684, 41.03022244443412],
                [-7.259483791431999, 41.01842868895876],
                [-7.334904920235844, 41.02232339527836],
                [-7.336196209452933, 40.934057532009675],
                [-7.374168761973185, 40.91250895152968],
                [-7.435973523937582, 40.824298488411216],
                [-7.479119957699971, 40.831959914874126],
                [-7.514061015605652, 40.869336924443],
                [-7.558450874063897, 40.873187697079544],
                [-7.60422181619044, 40.83759254857327],
                [-7.632781119098046, 40.87478558064696],
                [-7.688430177598285, 40.854572032519876],
                [-7.76741884884317, 40.97422418379256],
                [-7.81815200916869, 40.95532260632916],
                [-7.936210598531783, 41.01750030355753],
                [-7.967325884512329, 40.97669296977303],
                [-8.0196378823922, 40.964736821134004],
                [-8.089172179289967, 40.98755527766463],
                [-8.132036054625605, 40.94200390925644],
                [-8.127960444644192, 40.898150186034925],
                [-8.236763512471448, 40.82749158850852],
                [-8.28536297867586, 40.7808805928463],
                [-8.33708261852667, 40.77647766300306],
                [-8.369007143168169, 40.79991913302729],
                [-8.425322164009994, 40.770143971929805],
                [-8.496013092786107, 40.769954387916755],
                [-8.54731025044914, 40.78741262610868],
                [-8.550859416535507, 40.848988960306414],
                [-8.586747768369328, 40.889660352998746],
                [-8.614134836197783, 40.9709102571177],
                [-8.653119331726854, 40.96477827647259],
              ],
            ],
          },
          properties: { NUTS2: "Norte" },
        },
        {
          type: "Feature",
          geometry: {
            type: "MultiPolygon",
            coordinates: [
              [
                [
                  [-9.41645512572916, 39.0546970855211],
                  [-9.351103597317266, 39.05300642395194],
                  [-9.337674917763804, 39.01777476506507],
                  [-9.214363396688002, 39.01824830932376],
                  [-9.199474624468419, 38.95818487688367],
                  [-9.111037423483957, 38.92248993365143],
                  [-9.025564715215467, 38.96017125388501],
                  [-9.026573879563143, 39.01664402371602],
                  [-8.930276980476998, 39.01802485359567],
                  [-8.84714955761442, 39.01089239121599],
                  [-8.91188065324177, 38.88767690971293],
                  [-8.968466086132135, 38.82776495640026],
                  [-9.028455371023277, 38.84840152644198],
                  [-9.08633277485446, 38.79622531666834],
                  [-9.099754353242643, 38.72487174090243],
                  [-9.146549947087644, 38.694432931881245],
                  [-9.23794294816222, 38.68258375358085],
                  [-9.27895566740375, 38.69870813940853],
                  [-9.318717172680758, 38.67492680391816],
                  [-9.392177536227406, 38.702026408776064],
                  [-9.459484072970314, 38.69486693610893],
                  [-9.471983645055623, 38.75018241607201],
                  [-9.50052660716589, 38.78070939301157],
                  [-9.455669313396205, 38.85112387798503],
                  [-9.415337637161418, 38.94608310211932],
                  [-9.41645512572916, 39.0546970855211],
                ],
              ],
              [
                [
                  [-8.96699016914478, 38.826141963941424],
                  [-8.908341246445646, 38.74180496149228],
                  [-8.833540424806372, 38.761111170775834],
                  [-8.791581550929555, 38.73412171765559],
                  [-8.69692469776532, 38.840390631734145],
                  [-8.607219852915756, 38.77736350610597],
                  [-8.4909721256268, 38.76131061705447],
                  [-8.531214543155455, 38.69186096901616],
                  [-8.566213719249047, 38.678179937828126],
                  [-8.656531506296082, 38.60781259845035],
                  [-8.639113942565166, 38.54949117752527],
                  [-8.68161582827363, 38.5424897419183],
                  [-8.753693523230647, 38.470557723957405],
                  [-8.817892450525745, 38.464244038163486],
                  [-8.912867460989997, 38.502497545321134],
                  [-9.063948923933358, 38.43512156357925],
                  [-9.217569269695533, 38.42799485472227],
                  [-9.182537493728693, 38.521522968039555],
                  [-9.219326384981152, 38.61733542212172],
                  [-9.259043497521082, 38.663084631164296],
                  [-9.180065073962696, 38.67907392387539],
                  [-9.09973605490339, 38.648283178211194],
                  [-9.044810329844061, 38.68387483051969],
                  [-9.046313119397688, 38.71309107758944],
                  [-8.96699016914478, 38.826141963941424],
                ],
              ],
            ],
          },
          properties: { NUTS2: "Lisboa" },
        },
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [-8.930276980476998, 39.01802485359567],
                [-9.026573879563143, 39.01664402371602],
                [-9.025564715215467, 38.96017125388501],
                [-9.111037423483957, 38.92248993365143],
                [-9.199474624468419, 38.95818487688367],
                [-9.214363396688002, 39.01824830932376],
                [-9.337674917763804, 39.01777476506507],
                [-9.351103597317266, 39.05300642395194],
                [-9.41645512572916, 39.0546970855211],
                [-9.421966345727043, 39.074674515218064],
                [-9.359987064972577, 39.17034004241284],
                [-9.33564002931539, 39.27550480657707],
                [-9.367414445385647, 39.350308161598996],
                [-9.227005288926216, 39.43647815341347],
                [-9.074836522702313, 39.59182526771527],
                [-9.080556200273833, 39.63153989020669],
                [-9.029592800891644, 39.76919307731829],
                [-8.971880669082353, 39.8784374948268],
                [-8.895113451730513, 40.04458806985317],
                [-8.863043726596295, 40.140076014538884],
                [-8.90923754843909, 40.18653707493281],
                [-8.843119671966374, 40.33499207644682],
                [-8.751985178650738, 40.62225262547289],
                [-8.751414849012718, 40.656500675251976],
                [-8.672618836992955, 40.8868267239311],
                [-8.653119331726854, 40.96477827647259],
                [-8.614134836197783, 40.9709102571177],
                [-8.586747768369328, 40.889660352998746],
                [-8.550859416535507, 40.848988960306414],
                [-8.54731025044914, 40.78741262610868],
                [-8.496013092786107, 40.769954387916755],
                [-8.425322164009994, 40.770143971929805],
                [-8.369007143168169, 40.79991913302729],
                [-8.33708261852667, 40.77647766300306],
                [-8.28536297867586, 40.7808805928463],
                [-8.236763512471448, 40.82749158850852],
                [-8.127960444644192, 40.898150186034925],
                [-8.132036054625605, 40.94200390925644],
                [-8.089172179289967, 40.98755527766463],
                [-8.0196378823922, 40.964736821134004],
                [-7.967325884512329, 40.97669296977303],
                [-7.936210598531783, 41.01750030355753],
                [-7.81815200916869, 40.95532260632916],
                [-7.76741884884317, 40.97422418379256],
                [-7.688430177598285, 40.854572032519876],
                [-7.632781119098046, 40.87478558064696],
                [-7.60422181619044, 40.83759254857327],
                [-7.558450874063897, 40.873187697079544],
                [-7.514061015605652, 40.869336924443],
                [-7.479119957699971, 40.831959914874126],
                [-7.435973523937582, 40.824298488411216],
                [-7.374168761973185, 40.91250895152968],
                [-7.336196209452933, 40.934057532009675],
                [-7.334904920235844, 41.02232339527836],
                [-7.259483791431999, 41.01842868895876],
                [-7.178088566192684, 41.03022244443412],
                [-7.166821904880515, 40.97200904649631],
                [-7.045912274042355, 40.967491023037766],
                [-6.997353749559249, 40.98219975708748],
                [-6.973752423936464, 41.03497264224431],
                [-6.931356647135623, 41.02941112501444],
                [-6.859958630871818, 40.9507754334647],
                [-6.803280560393915, 40.84622168436562],
                [-6.830528144905113, 40.74295431232935],
                [-6.798901456844763, 40.655313926514225],
                [-6.845357380848007, 40.5659539531216],
                [-6.800862064635775, 40.55035325788366],
                [-6.810826402346342, 40.505166893830626],
                [-6.849692714799065, 40.451519604123774],
                [-6.83872132034838, 40.41473123328285],
                [-6.781214957071219, 40.363803112536694],
                [-6.790332309483059, 40.33422618528438],
                [-6.863919082984366, 40.29618724511311],
                [-6.867989729381076, 40.26434214693056],
                [-6.951417747009962, 40.25757275625675],
                [-7.008893013177873, 40.2273192890063],
                [-7.018949110369713, 40.14259466354383],
                [-6.954224508369794, 40.115606666130006],
                [-6.880929699421773, 40.041675101174754],
                [-6.885507487875535, 39.94013273775317],
                [-6.903591164241557, 39.870705417662364],
                [-6.987204105421913, 39.81002716631224],
                [-6.976922450436277, 39.77198532477137],
                [-7.015516181647712, 39.67042767209238],
                [-7.151349673220837, 39.653071847052686],
                [-7.251521679009305, 39.666810511031436],
                [-7.331160580098883, 39.64099427880355],
                [-7.454203073528392, 39.66218113872044],
                [-7.541680166315928, 39.66367957825821],
                [-7.637442481409233, 39.62272354420859],
                [-7.673108476666275, 39.64890940126349],
                [-7.72230552560275, 39.59326756587843],
                [-7.822352106954303, 39.53991483379681],
                [-7.84774989877656, 39.51070460224476],
                [-7.959617696349625, 39.5610467346725],
                [-8.000142833784789, 39.50493794193581],
                [-8.00629947847759, 39.46181167026155],
                [-7.943168165618731, 39.42022702299831],
                [-8.0885208135434, 39.27517147547068],
                [-8.134468539436767, 39.287400085332344],
                [-8.15138251704885, 39.24956713702404],
                [-8.217208964187316, 39.25293399630623],
                [-8.261499695210013, 39.29215676662591],
                [-8.298296024180381, 39.39908066569199],
                [-8.344522079110632, 39.463789661863764],
                [-8.442475851280854, 39.456849210764275],
                [-8.537248535838746, 39.39713929068026],
                [-8.595279913781866, 39.405395909502005],
                [-8.62551889364995, 39.433509652121465],
                [-8.69662294231443, 39.39909117279636],
                [-8.714875413440463, 39.44146367236231],
                [-8.80231099983147, 39.48300213441998],
                [-8.903429356755314, 39.46600435285939],
                [-8.945698986952188, 39.391064074306584],
                [-9.00154955301769, 39.35476364615499],
                [-8.975224499102813, 39.28713637970193],
                [-9.004548097499592, 39.18727379522175],
                [-8.971194938525851, 39.17876018905836],
                [-8.918695735971635, 39.10605909743707],
                [-8.955667572255695, 39.03960840263154],
                [-8.930276980476998, 39.01802485359567],
              ],
            ],
          },
          properties: { NUTS2: "Centro" },
        },
      ],
    },
    markers: [
      { long: -8.678684, lat: 41.145368 }, // Porto -> Norte
      { long: -8.870827, lat: 40.15289 }, // Figueira da Foz -> Centro
      { long: -9.48542, lat: 38.803396 }, // Lisboa -> Lisboa e Vale do Tejo ,
      { long: -1.397, lat: 43.664 }, // Vila Nova de Milfontes -> Alentejo  // FALTA A PARTIR DAAQUI
      { long: 3.075, lat: 50.64 }, // Sagres -> Algarve
      { long: -3.83, lat: 58 }, // Ponta Delgada -> Açores
      { long: -3.83, lat: 58 }, // Funchal - Madeira  ,
    ],
    portugalContinental: {
      type: "FeatureCollection",
      crs: {
        type: "name",
        properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
      },
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [-7.442020650983559, 37.1766355919328],
                [-7.506191176140689, 37.16759107080952],
                [-7.601539413649471, 37.128009388562575],
                [-7.814599770581366, 36.996107032439674],
                [-7.901245299073844, 36.96336528426251],
                [-7.948070472178702, 36.9773231376231],
                [-8.09555016561552, 37.064075746678704],
                [-8.184747078244028, 37.0905513316266],
                [-8.286607924465532, 37.072743429533155],
                [-8.371120796796976, 37.101654209124916],
                [-8.436777481135902, 37.08533001493058],
                [-8.529500166757359, 37.11567983537223],
                [-8.640095262559207, 37.11873855742335],
                [-8.685742375587836, 37.085342196228936],
                [-8.82578858975193, 37.06439229272715],
                [-8.949126110691605, 37.00829379261069],
                [-8.978633700770711, 37.056056267885566],
                [-8.903384220685536, 37.16544300607765],
                [-8.909373641243048, 37.18937598963247],
                [-8.86020489938705, 37.26949162943123],
                [-8.871818768349971, 37.322003254364986],
                [-8.808506438467637, 37.413773054812914],
                [-8.785668102606763, 37.53279521851557],
                [-8.819276320159874, 37.59843963070621],
                [-8.795452202557815, 37.67561004727595],
                [-8.807137595120674, 37.774805505978335],
                [-8.789948001888497, 37.837639673800446],
                [-8.815417777451739, 37.931287630135316],
                [-8.883879187507848, 37.95004756359345],
                [-8.819485689305852, 38.0619663141067],
                [-8.780582780785386, 38.181964188336565],
                [-8.776450929813988, 38.262152259768314],
                [-8.804726135135354, 38.38276809067481],
                [-8.834519522570213, 38.42929750696997],
                [-8.910599099835126, 38.48734098094849],
                [-8.981719702359197, 38.47868066708365],
                [-9.063948923933358, 38.43512156357925],
                [-9.217569269695533, 38.42799485472227],
                [-9.182537493728693, 38.521522968039555],
                [-9.219326384981152, 38.61733542212172],
                [-9.259043497521082, 38.663084631164296],
                [-9.180065073962696, 38.67907392387539],
                [-9.09973605490339, 38.648283178211194],
                [-9.044810329844061, 38.68387483051969],
                [-9.046313119397688, 38.71309107758944],
                [-8.988445181390784, 38.785138516991644],
                [-9.028455371023277, 38.84840152644198],
                [-9.08633277485446, 38.79622531666834],
                [-9.099754353242643, 38.72487174090243],
                [-9.146549947087644, 38.694432931881245],
                [-9.23794294816222, 38.68258375358085],
                [-9.27895566740375, 38.69870813940853],
                [-9.318717172680758, 38.67492680391816],
                [-9.392177536227406, 38.702026408776064],
                [-9.459484072970314, 38.69486693610893],
                [-9.471983645055623, 38.75018241607201],
                [-9.50052660716589, 38.78070939301157],
                [-9.455669313396205, 38.85112387798503],
                [-9.415337637161418, 38.94608310211932],
                [-9.421966345727043, 39.074674515218064],
                [-9.359987064972577, 39.17034004241284],
                [-9.33564002931539, 39.27550480657707],
                [-9.367414445385647, 39.350308161598996],
                [-9.227005288926216, 39.43647815341347],
                [-9.074836522702313, 39.59182526771527],
                [-9.080556200273833, 39.63153989020669],
                [-9.029592800891644, 39.76919307731829],
                [-8.971880669082353, 39.8784374948268],
                [-8.895113451730513, 40.04458806985317],
                [-8.863043726596295, 40.140076014538884],
                [-8.90923754843909, 40.18653707493281],
                [-8.843119671966374, 40.33499207644682],
                [-8.751985178650738, 40.62225262547289],
                [-8.751414849012718, 40.656500675251976],
                [-8.672618836992955, 40.8868267239311],
                [-8.64417775212388, 41.01781908292695],
                [-8.668025979836557, 41.1419507986743],
                [-8.716368416081696, 41.203165223034645],
                [-8.741381756298608, 41.33659476631176],
                [-8.788907269389842, 41.4166840524834],
                [-8.777397279505161, 41.481343945619614],
                [-8.83059535381902, 41.67781095879089],
                [-8.88105831699491, 41.75269346808333],
                [-8.871529779443623, 41.86390077556161],
                [-8.818381394432846, 41.91128615277701],
                [-8.747727265795529, 41.943141923198596],
                [-8.745968006193355, 41.96454549869689],
                [-8.661358752332776, 42.00331763735916],
                [-8.636780978785309, 42.0474866207606],
                [-8.546836111539417, 42.05398891427905],
                [-8.518053523131467, 42.07955538761205],
                [-8.42894217724946, 42.072286535938794],
                [-8.331890767529124, 42.08383452718637],
                [-8.253635374029635, 42.13668423593164],
                [-8.195388608852012, 42.14819997519456],
                [-8.186149821859695, 42.072585064822015],
                [-8.11870076060627, 42.082306565268006],
                [-8.086034780896856, 42.016492173445364],
                [-8.163019279228305, 41.98269174686339],
                [-8.216899264091124, 41.913154786390145],
                [-8.161772355530784, 41.860975046340194],
                [-8.1650131120599, 41.818113179125234],
                [-8.093917054058435, 41.80803533009123],
                [-8.01260258796392, 41.83353170615205],
                [-7.988667451981915, 41.86751756436568],
                [-7.92130457924723, 41.88181420050975],
                [-7.73335377616781, 41.89227694751503],
                [-7.70414248631645, 41.907339790734774],
                [-7.573911886679392, 41.82971993894492],
                [-7.452499308075502, 41.86508167205012],
                [-7.389964241709365, 41.84230540663632],
                [-7.315315074519633, 41.84254537689551],
                [-7.196737819325854, 41.879979369411515],
                [-7.173649151692826, 41.918919610559136],
                [-7.186659538750194, 41.969433806675],
                [-7.141458767911827, 41.990757774549664],
                [-7.07695584659362, 41.95193726458661],
                [-6.821606734661361, 41.94600536352155],
                [-6.76868582241142, 41.98409022614214],
                [-6.750582410933438, 41.94364551920959],
                [-6.700005809454407, 41.933315058314854],
                [-6.599459047409037, 41.94830277853268],
                [-6.545964674176445, 41.93079220870259],
                [-6.571179417211172, 41.8834954290125],
                [-6.517640132611733, 41.875088173624036],
                [-6.564473648612476, 41.75168287294005],
                [-6.548273747748854, 41.685595327147716],
                [-6.512554859536072, 41.661223693161794],
                [-6.452362909058614, 41.68090973591883],
                [-6.354666627221147, 41.6764052386439],
                [-6.254458242376243, 41.63299941359287],
                [-6.189159307482876, 41.57483180148935],
                [-6.305281967803408, 41.44970972849684],
                [-6.315408635915114, 41.39006858975073],
                [-6.41526868731382, 41.34795850977315],
                [-6.468847959420782, 41.301276187663056],
                [-6.580523323653452, 41.23905822284957],
                [-6.648377518568997, 41.24755030150994],
                [-6.701469641844634, 41.18028137708424],
                [-6.767871220287496, 41.134837080187665],
                [-6.754193816823948, 41.104061669296755],
                [-6.808931005375524, 41.03649648530623],
                [-6.931633073205168, 41.0168104053785],
                [-6.859958630871818, 40.9507754334647],
                [-6.838053016521473, 40.89012918990917],
                [-6.803280560393915, 40.84622168436562],
                [-6.830528144905113, 40.74295431232935],
                [-6.798901456844763, 40.655313926514225],
                [-6.845357380848007, 40.5659539531216],
                [-6.800862064635775, 40.55035325788366],
                [-6.810826402346342, 40.505166893830626],
                [-6.849692714799065, 40.451519604123774],
                [-6.83872132034838, 40.41473123328285],
                [-6.781214957071219, 40.363803112536694],
                [-6.790332309483059, 40.33422618528438],
                [-6.863919082984366, 40.29618724511311],
                [-6.867989729381076, 40.26434214693056],
                [-6.951417747009962, 40.25757275625675],
                [-7.008893013177873, 40.2273192890063],
                [-7.018949110369713, 40.14259466354383],
                [-6.954224508369794, 40.115606666130006],
                [-6.880929699421773, 40.041675101174754],
                [-6.885507487875535, 39.94013273775317],
                [-6.903591164241557, 39.870705417662364],
                [-6.987204105421913, 39.81002716631224],
                [-6.976922450436277, 39.77198532477137],
                [-7.015516181647712, 39.67042767209238],
                [-7.151349673220837, 39.653071847052686],
                [-7.251521679009305, 39.666810511031436],
                [-7.331160580098883, 39.64099427880355],
                [-7.533760740316414, 39.66716553118002],
                [-7.499407968251364, 39.58960717104199],
                [-7.37714088853758, 39.48924642605382],
                [-7.294261007602349, 39.45682754296764],
                [-7.322854948800315, 39.381952803484616],
                [-7.310341038498378, 39.340960926543254],
                [-7.231471694241703, 39.27825850568684],
                [-7.248018404805054, 39.253375510666174],
                [-7.221607101389341, 39.1932998116419],
                [-7.132222900170709, 39.16453573880126],
                [-7.144024855972424, 39.10863269649866],
                [-7.028190804793474, 39.11565325819428],
                [-6.980903488309268, 39.08782071967703],
                [-6.951130314157039, 39.02359807048164],
                [-7.052181022659306, 38.90695336259495],
                [-7.033021736492887, 38.8788249789387],
                [-7.096372576386986, 38.815966503721775],
                [-7.125404023010635, 38.81532738916861],
                [-7.202783738830153, 38.7520648317311],
                [-7.260033845634311, 38.722942510248046],
                [-7.272498020053357, 38.63840595257624],
                [-7.263905197506062, 38.58786898204048],
                [-7.303360830742494, 38.54310174306768],
                [-7.317897700723123, 38.44008741598935],
                [-7.284333255946806, 38.423178115787955],
                [-7.248174789331525, 38.36951230255234],
                [-7.142943831203078, 38.2617146663855],
                [-7.09722460375829, 38.17895317579036],
                [-7.02859886303, 38.18251471059862],
                [-6.942298747516331, 38.21808941351554],
                [-6.969231114383495, 38.16048274983521],
                [-6.95939736565848, 38.13346787475453],
                [-6.989833360452336, 38.08996455749157],
                [-7.007185941694111, 38.02075197869294],
                [-7.044719216752107, 38.0139943743601],
                [-7.108291160458424, 38.04093370597595],
                [-7.128732968483519, 38.001699010658385],
                [-7.245569374762359, 37.991799230770816],
                [-7.254785311381209, 37.92369601768328],
                [-7.321255857203382, 37.81868601578429],
                [-7.418977276268115, 37.74348478679676],
                [-7.444830539074613, 37.6639327759343],
                [-7.522665146692645, 37.55306520007299],
                [-7.490268932187177, 37.51837885842],
                [-7.440476573419271, 37.39101532421058],
                [-7.441099722222586, 37.30730649514952],
                [-7.422044481466808, 37.274851569278844],
                [-7.404364957229204, 37.170750986170496],
                [-7.442020650983559, 37.1766355919328],
              ],
            ],
          },
          properties: { NUTS2: "Portugal" },
        },
      ],
    },
    madeira: {
      type: "FeatureCollection",
      crs: {
        type: "name",
        properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
      },
      features: [
        {
          type: "Feature",
          geometry: {
            type: "MultiPolygon",
            coordinates: [
              [
                [
                  [-16.674149701674708, 32.73272296005142],
                  [-16.671906643552735, 32.73595440929739],
                  [-16.665712222193115, 32.73418715553379],
                  [-16.662774414744213, 32.73007376014387],
                  [-16.674149701674708, 32.73272296005142],
                ],
              ],
              [
                [
                  [-16.38210959283739, 33.01887043705736],
                  [-16.381229931542173, 33.01063326705463],
                  [-16.383483827397587, 33.00597855163256],
                  [-16.392048695567464, 33.002947209386306],
                  [-16.392613253548884, 33.00942049949759],
                  [-16.388785274605556, 33.01073089295582],
                  [-16.38210959283739, 33.01887043705736],
                ],
              ],
              [
                [
                  [-16.388715054745926, 33.04572005598641],
                  [-16.380365174713535, 33.053382379395764],
                  [-16.37957077141339, 33.057925900355016],
                  [-16.375745147002, 33.06012658777692],
                  [-16.377151022063742, 33.06353432184255],
                  [-16.37417876108445, 33.070031017895985],
                  [-16.376522308685157, 33.07587764810288],
                  [-16.369904988383908, 33.07731828364283],
                  [-16.371616793286268, 33.0814414851813],
                  [-16.360147127948267, 33.083070540845284],
                  [-16.35531750363977, 33.09034607036209],
                  [-16.34942386197969, 33.09224504777765],
                  [-16.342702115790683, 33.09758299087141],
                  [-16.333359454521595, 33.09902197872797],
                  [-16.32472245990062, 33.10400964763492],
                  [-16.313409653159695, 33.103148383174144],
                  [-16.30940658149628, 33.097290440884215],
                  [-16.301198993247006, 33.09643755592509],
                  [-16.29378884337063, 33.097983872143786],
                  [-16.293579916402365, 33.0935197796314],
                  [-16.298983746099225, 33.087136976067036],
                  [-16.29210649051032, 33.07915294977241],
                  [-16.298073279265186, 33.06853216017062],
                  [-16.29464938239523, 33.06304553925117],
                  [-16.29891324118961, 33.05969821712151],
                  [-16.302410386544608, 33.061880043317665],
                  [-16.31294931738629, 33.06302050256592],
                  [-16.323844184050287, 33.061845572142516],
                  [-16.335172731334193, 33.056638597192126],
                  [-16.34280554602251, 33.05117805416236],
                  [-16.35684421253348, 33.03786577932568],
                  [-16.364395353229398, 33.03216869799936],
                  [-16.377416247495304, 33.02421988800422],
                  [-16.38107182186081, 33.02456329347049],
                  [-16.384342425897202, 33.02984978826434],
                  [-16.39134411295729, 33.03114741033128],
                  [-16.396352278717327, 33.041135634072674],
                  [-16.388489112875828, 33.04225822549937],
                  [-16.388715054745926, 33.04572005598641],
                ],
              ],
              [
                [
                  [-16.80750826807821, 32.660141212813194],
                  [-16.815518245783043, 32.65132991296824],
                  [-16.8213191890153, 32.65033098266905],
                  [-16.829538327520087, 32.64199305365711],
                  [-16.84690513535351, 32.64009917345181],
                  [-16.852338561554514, 32.63808438309823],
                  [-16.869963430641686, 32.64349751788642],
                  [-16.88476546437412, 32.646160410636],
                  [-16.89608492623246, 32.64708334634906],
                  [-16.911106171502798, 32.64594984929294],
                  [-16.91520002369266, 32.64267119086199],
                  [-16.922727376167266, 32.641139897469685],
                  [-16.925899393516655, 32.637247226604046],
                  [-16.93866318611893, 32.635878494572495],
                  [-16.939341501398342, 32.633769461369795],
                  [-16.946369788501215, 32.63314246898887],
                  [-16.94786020835445, 32.63659324643601],
                  [-16.95406062651939, 32.64126232144889],
                  [-16.960035630832433, 32.643240732323314],
                  [-16.971640339960796, 32.644494727248315],
                  [-16.974605262348106, 32.64787355060738],
                  [-16.987084147412073, 32.65138897991604],
                  [-16.994435667929146, 32.65083486818261],
                  [-16.99845871892975, 32.65292304342778],
                  [-17.016289273673163, 32.65239088851686],
                  [-17.026058178909683, 32.65617929625048],
                  [-17.03055719511041, 32.65962808950598],
                  [-17.042612629831254, 32.66389688214402],
                  [-17.04972347517785, 32.6648660026138],
                  [-17.061088894000346, 32.66923082416903],
                  [-17.064560176671517, 32.668645901707166],
                  [-17.071852022517845, 32.67523170944315],
                  [-17.086715501869282, 32.679597728391684],
                  [-17.089510430542326, 32.678427883120605],
                  [-17.095993958322207, 32.6809635892957],
                  [-17.103851814238883, 32.6783540013338],
                  [-17.120732063690703, 32.69128046955325],
                  [-17.12700222251629, 32.6941203290021],
                  [-17.131700386548975, 32.69916258464011],
                  [-17.13862650803347, 32.70335097557241],
                  [-17.14374439078119, 32.70371583207585],
                  [-17.149204010867763, 32.70836659832371],
                  [-17.15909699151056, 32.71162464520885],
                  [-17.171149502178405, 32.71810767751918],
                  [-17.182751721689524, 32.72262961876115],
                  [-17.191175964715452, 32.72491046537196],
                  [-17.19794720042344, 32.73035867540461],
                  [-17.202721001727873, 32.731662425814925],
                  [-17.21282678647161, 32.73719795720446],
                  [-17.21279556708316, 32.74050996644996],
                  [-17.21629884597041, 32.74628061880805],
                  [-17.220341881362387, 32.74763169157963],
                  [-17.223179629772368, 32.752071174253416],
                  [-17.228305486918785, 32.75458266188515],
                  [-17.23459982976592, 32.764247275103216],
                  [-17.240261930539575, 32.7677802984565],
                  [-17.244631066586905, 32.773236544874635],
                  [-17.24346056218249, 32.78289407824529],
                  [-17.246873392133207, 32.78929319451797],
                  [-17.24745930782197, 32.79379145477206],
                  [-17.251258925314556, 32.8018866543483],
                  [-17.262687299552535, 32.809087628711765],
                  [-17.26565105300206, 32.81519479061985],
                  [-17.25791724759247, 32.8183933548758],
                  [-17.246624633836436, 32.82740940673639],
                  [-17.24374041028659, 32.833058982811615],
                  [-17.22917046460723, 32.84121506841637],
                  [-17.220579226244222, 32.84815839539909],
                  [-17.2144615923159, 32.856093975900926],
                  [-17.208145045103283, 32.85859968557894],
                  [-17.20117634460485, 32.86740879650614],
                  [-17.19650183185281, 32.87015925714536],
                  [-17.187962276408733, 32.869749052660815],
                  [-17.17815226990258, 32.86659634684457],
                  [-17.171352497659512, 32.868917382488604],
                  [-17.167025628419967, 32.86790407588373],
                  [-17.16399056953152, 32.862243553259326],
                  [-17.151406072632053, 32.85490376781191],
                  [-17.14952610642127, 32.847414345527056],
                  [-17.140890823327908, 32.842504540727084],
                  [-17.136825498046026, 32.83563960083196],
                  [-17.12519083105496, 32.82756078155682],
                  [-17.117524656191264, 32.82598110903865],
                  [-17.105921939809974, 32.82680753861921],
                  [-17.103424454199807, 32.82139569713439],
                  [-17.099783405635993, 32.8180496361654],
                  [-17.0921570429763, 32.81525044506302],
                  [-17.081890258453857, 32.81607211858723],
                  [-17.074345740080812, 32.81354325945831],
                  [-17.071727093972353, 32.81421753992675],
                  [-17.059679161363263, 32.81022257001715],
                  [-17.050671343141435, 32.80842325918636],
                  [-17.04426414242007, 32.8119265719105],
                  [-17.040038709564605, 32.811214172262254],
                  [-17.027528892485044, 32.81509200566209],
                  [-17.016727888440695, 32.81541701999853],
                  [-17.00726294225637, 32.820993756050235],
                  [-17.00093807565179, 32.822195358960776],
                  [-16.991623986620013, 32.8295827436575],
                  [-16.986296502945436, 32.82971043781277],
                  [-16.983048772934637, 32.826727279631136],
                  [-16.97691522105285, 32.828195381458755],
                  [-16.969037639063124, 32.82658390621005],
                  [-16.960931664672195, 32.827296116168625],
                  [-16.948919267379807, 32.83059719062698],
                  [-16.930896133577768, 32.83174776981863],
                  [-16.923014407216492, 32.83294170413205],
                  [-16.910611723557707, 32.83652570375069],
                  [-16.90426264074615, 32.8363072919501],
                  [-16.89756212126638, 32.83004355007088],
                  [-16.8867885606799, 32.82820478556881],
                  [-16.88230296310171, 32.82529224372703],
                  [-16.876199936215457, 32.81706209067092],
                  [-16.86051441552931, 32.80629838397433],
                  [-16.859720904485204, 32.79969176270865],
                  [-16.854462036472466, 32.79401947566163],
                  [-16.84924465814341, 32.79430195060148],
                  [-16.84549331069677, 32.78849934934748],
                  [-16.835341037894903, 32.78657118827336],
                  [-16.83082339373536, 32.78000729746445],
                  [-16.83108813374011, 32.77704086751005],
                  [-16.82649015849379, 32.77418034233163],
                  [-16.822441936487564, 32.768141552003755],
                  [-16.81785757099668, 32.768838845543705],
                  [-16.802990355495798, 32.76528521325281],
                  [-16.79800668066023, 32.76247019129265],
                  [-16.78923494390257, 32.766193836902694],
                  [-16.78283538266733, 32.75935725413262],
                  [-16.777956985437296, 32.75768846500672],
                  [-16.772819422078843, 32.75931124820289],
                  [-16.76591601313056, 32.75922169103951],
                  [-16.75756293935655, 32.75727129739956],
                  [-16.744926058801067, 32.75565992519731],
                  [-16.73738257656943, 32.74863680770512],
                  [-16.733529331598792, 32.74838190549718],
                  [-16.72763541935503, 32.75159315559837],
                  [-16.726558225641075, 32.74834746435497],
                  [-16.718340526340942, 32.74730517767464],
                  [-16.709462358122046, 32.75183148764573],
                  [-16.705741809330313, 32.74948854352561],
                  [-16.699065557406403, 32.748294935532755],
                  [-16.696384095306396, 32.750841080481],
                  [-16.690397326409865, 32.750749902048774],
                  [-16.690360124633855, 32.74745836301278],
                  [-16.681767982543487, 32.74656013517686],
                  [-16.680111677206916, 32.743851287705986],
                  [-16.682754032497307, 32.73960771836508],
                  [-16.68677278848092, 32.744610952928156],
                  [-16.696438150312442, 32.74745460368002],
                  [-16.699039891279426, 32.74153479663081],
                  [-16.703374489681316, 32.7388618818035],
                  [-16.71637570728935, 32.742662698491934],
                  [-16.725830808782312, 32.74232171284908],
                  [-16.7356235564301, 32.735787333401724],
                  [-16.74517954742506, 32.735015645472565],
                  [-16.74876649451143, 32.73161898819119],
                  [-16.74858452285024, 32.728371004710475],
                  [-16.753573098680864, 32.72571107457972],
                  [-16.755815032211885, 32.72010967705902],
                  [-16.762025934139256, 32.71872101250578],
                  [-16.764965899952514, 32.715544042846126],
                  [-16.76188781282447, 32.7119575703088],
                  [-16.76142810924933, 32.70714240551962],
                  [-16.769644540350154, 32.70045217460289],
                  [-16.773579644309216, 32.69106070696504],
                  [-16.783778690840574, 32.687734908553935],
                  [-16.790088215252705, 32.687666123134235],
                  [-16.793512885183347, 32.68499314495655],
                  [-16.80159608955737, 32.67389975635452],
                  [-16.803660564390338, 32.66287413133893],
                  [-16.80750826807821, 32.660141212813194],
                ],
              ],
            ],
          },
          properties: { NUTS2: "Região Autónoma da Madeira" },
        },
      ],
    },
    acores: {
      type: "FeatureCollection",
      crs: {
        type: "name",
        properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
      },
      features: [
        {
          type: "Feature",
          geometry: {
            type: "MultiPolygon",
            coordinates: [
              [
                [
                  [-25.046169491518448, 36.98412295929784],
                  [-25.020162287135967, 36.96567505140512],
                  [-25.01320193821588, 36.940475642474176],
                  [-25.045100859573324, 36.929353667881756],
                  [-25.066757579103484, 36.928624333231255],
                  [-25.083222584941176, 36.944544678093216],
                  [-25.102959151275265, 36.95195275630352],
                  [-25.157488466048687, 36.93994302219966],
                  [-25.17239132605749, 36.951521045415376],
                  [-25.174307616891326, 36.968621442322785],
                  [-25.18526151507861, 36.98574097730024],
                  [-25.168630010925984, 37.004354421017894],
                  [-25.14597412338855, 37.007391572501035],
                  [-25.119258202362552, 37.0036069365191],
                  [-25.08754972325092, 37.01657451384495],
                  [-25.074480062675896, 37.00981299518378],
                  [-25.059060771173886, 37.01530529739657],
                  [-25.046169491518448, 36.98412295929784],
                ],
              ],
              [
                [
                  [-25.67766495269325, 37.733935989525854],
                  [-25.703905591291598, 37.73863087514765],
                  [-25.73998150419445, 37.76485403943496],
                  [-25.775590238416342, 37.78282470429842],
                  [-25.78783010617377, 37.79837627267684],
                  [-25.815526232997176, 37.81008959246658],
                  [-25.84079831119401, 37.82819781964298],
                  [-25.854599249938378, 37.860655902027055],
                  [-25.824162448026417, 37.885551445990025],
                  [-25.821359152052736, 37.899694929495254],
                  [-25.80251749691261, 37.89682927544063],
                  [-25.780639198150098, 37.91054203480289],
                  [-25.718492214803796, 37.88690478574073],
                  [-25.698701717238798, 37.863956123852724],
                  [-25.69292787321836, 37.84458631283728],
                  [-25.675825355363724, 37.83538217954582],
                  [-25.639494968742028, 37.82862667651009],
                  [-25.628208836823042, 37.83312608281158],
                  [-25.586239968216837, 37.813819287532105],
                  [-25.553754544844928, 37.81545841523158],
                  [-25.525939098576128, 37.823551305533705],
                  [-25.491033550779214, 37.843831969555694],
                  [-25.46953083652483, 37.82484163394037],
                  [-25.44011227830936, 37.82324931497032],
                  [-25.403212089175295, 37.827327000365976],
                  [-25.360378645309936, 37.840679211282435],
                  [-25.329395978545932, 37.8532793431975],
                  [-25.324174009146226, 37.86261051809614],
                  [-25.288859238430966, 37.855469834696],
                  [-25.277561040822448, 37.86039020132725],
                  [-25.242899121727085, 37.85382307929319],
                  [-25.193753943177217, 37.85960560608447],
                  [-25.164787950403117, 37.8558253008543],
                  [-25.147198478187146, 37.84808491802982],
                  [-25.134196912804104, 37.80745615903303],
                  [-25.14370412116363, 37.79330811855853],
                  [-25.138974000400268, 37.78104233922985],
                  [-25.149681566290894, 37.75837891855529],
                  [-25.17318324979791, 37.74192222829817],
                  [-25.21749264693409, 37.73679479023049],
                  [-25.2520196914591, 37.747139883802895],
                  [-25.276172925469666, 37.743976544238635],
                  [-25.320829609266642, 37.7253529639113],
                  [-25.351516660461755, 37.72444641670613],
                  [-25.367971521342344, 37.713601140753006],
                  [-25.38836696019911, 37.716625136753876],
                  [-25.443119737825267, 37.71170503280064],
                  [-25.482568872986967, 37.71852333184314],
                  [-25.509121959259605, 37.70737834351486],
                  [-25.567428622626917, 37.743578515924064],
                  [-25.583878928058404, 37.74056328286874],
                  [-25.623259455236948, 37.75039703537587],
                  [-25.67766495269325, 37.733935989525854],
                ],
              ],
              [
                [
                  [-28.62600626716919, 38.533882297877845],
                  [-28.632179836280116, 38.52428490942001],
                  [-28.673329700509484, 38.522951625363376],
                  [-28.720110898379936, 38.51734002247465],
                  [-28.74606897631433, 38.52413314379691],
                  [-28.75821430536735, 38.55928402462206],
                  [-28.780815451052597, 38.565033697625694],
                  [-28.82208517913606, 38.58652040954809],
                  [-28.8333107844926, 38.603673114895955],
                  [-28.791451664499355, 38.61174660120419],
                  [-28.7571143350719, 38.61023995978568],
                  [-28.71492157445271, 38.64409431546438],
                  [-28.684663036217287, 38.63860478616623],
                  [-28.661942834135665, 38.629711497968636],
                  [-28.648065627519216, 38.617039927891646],
                  [-28.621691974632768, 38.61104209936933],
                  [-28.604247219777175, 38.599658124622984],
                  [-28.611719603381793, 38.561356939948375],
                  [-28.60342684310062, 38.548756002141694],
                  [-28.62600626716919, 38.533882297877845],
                ],
              ],
              [
                [
                  [-27.139388613189393, 38.64457785977975],
                  [-27.164701233101123, 38.65354784761124],
                  [-27.267197778747366, 38.65702209629097],
                  [-27.286486663736824, 38.654926172628684],
                  [-27.311255113513134, 38.6693359674464],
                  [-27.35173262763565, 38.68749280560375],
                  [-27.36890773810216, 38.70701799080689],
                  [-27.37943575947593, 38.746536995085165],
                  [-27.365984418101117, 38.7749621183937],
                  [-27.331705447978567, 38.794033989994254],
                  [-27.305349272550558, 38.79911184913138],
                  [-27.255861992314518, 38.80325895185904],
                  [-27.22939004458255, 38.79135087687991],
                  [-27.1823477315399, 38.7964395972692],
                  [-27.12974964806082, 38.78108180297462],
                  [-27.103367376213967, 38.785713135719234],
                  [-27.060870954960553, 38.759455813879704],
                  [-27.04961134963236, 38.73764353960773],
                  [-27.060933680487512, 38.72854539610927],
                  [-27.061041170748773, 38.71452294901152],
                  [-27.042681598510022, 38.70332492274909],
                  [-27.046601568385917, 38.68991827926996],
                  [-27.075592557178524, 38.670617863443354],
                  [-27.077441701589443, 38.64422072527646],
                  [-27.11653744834368, 38.6472524199453],
                  [-27.139388613189393, 38.64457785977975],
                ],
              ],
              [
                [
                  [-31.11414314229214, 39.66922840105702],
                  [-31.12875800565952, 39.702114395261184],
                  [-31.11531036676832, 39.72422211844902],
                  [-31.088008054018268, 39.71921738483336],
                  [-31.08217545153078, 39.7069451579121],
                  [-31.08717402286065, 39.68649546078183],
                  [-31.11414314229214, 39.66922840105702],
                ],
              ],
              [
                [
                  [-31.230853509212878, 39.37114938066247],
                  [-31.247818146824798, 39.37564993047593],
                  [-31.259160344130215, 39.39809744216017],
                  [-31.252012737496656, 39.40597754023542],
                  [-31.263045759939715, 39.419008896212844],
                  [-31.260034676081077, 39.43639801599619],
                  [-31.26863630187464, 39.451248566018734],
                  [-31.25884711114299, 39.46047259496375],
                  [-31.257843679788937, 39.48889254938126],
                  [-31.232686458512294, 39.51436615771508],
                  [-31.213593717781684, 39.52029563830187],
                  [-31.20241208159182, 39.508329541264104],
                  [-31.172956908044, 39.497585784419705],
                  [-31.158741815980086, 39.502393519285356],
                  [-31.12718420127561, 39.44969189460022],
                  [-31.143303759478652, 39.43743557350459],
                  [-31.14223296554132, 39.42594451355488],
                  [-31.16033169515541, 39.38550308693911],
                  [-31.192535200290493, 39.37280835939181],
                  [-31.220624959518812, 39.37579112216965],
                  [-31.230853509212878, 39.37114938066247],
                ],
              ],
              [
                [
                  [-27.948685479877167, 39.02133727861341],
                  [-27.970242297044646, 39.00858505353471],
                  [-27.99872629821279, 39.01436403724463],
                  [-28.017868052025722, 39.02461986295921],
                  [-28.03239229777232, 39.02212234724917],
                  [-28.050890173565907, 39.04020906125692],
                  [-28.070868579833633, 39.07016896849493],
                  [-28.044495210961042, 39.09558473255604],
                  [-28.033744633339154, 39.09701865516827],
                  [-27.999993505962767, 39.08877506515153],
                  [-27.990385853902247, 39.081882747389805],
                  [-27.95630159143171, 39.04011722965211],
                  [-27.948685479877167, 39.02133727861341],
                ],
              ],
              [
                [
                  [-28.066210583923468, 38.44442888895395],
                  [-28.035062177890314, 38.42879325363236],
                  [-28.02932650356919, 38.411468687658775],
                  [-28.08117857106078, 38.39963760457089],
                  [-28.118046470365435, 38.40610016016921],
                  [-28.172158319317436, 38.40954102240049],
                  [-28.186943929451886, 38.40666169578696],
                  [-28.196895414693294, 38.39500719185148],
                  [-28.228567881272642, 38.384568138805164],
                  [-28.25589911350108, 38.3910103008125],
                  [-28.2562164985894, 38.403645932827644],
                  [-28.27256392915792, 38.41244077434423],
                  [-28.299128922987425, 38.410914735406514],
                  [-28.35171943488486, 38.41581260618288],
                  [-28.363642648326646, 38.41233555634906],
                  [-28.41079983223796, 38.427831513922385],
                  [-28.44646176620291, 38.42091727837099],
                  [-28.464510701205494, 38.43280468421381],
                  [-28.479486861197987, 38.4356714866957],
                  [-28.51520305485678, 38.45117888466026],
                  [-28.521274587417764, 38.46507695167838],
                  [-28.539561624992626, 38.485212939849156],
                  [-28.541887345392936, 38.50883294264999],
                  [-28.53708522642694, 38.52983406024019],
                  [-28.519287422070896, 38.54551641712761],
                  [-28.477087924271153, 38.56049740233739],
                  [-28.448118583296647, 38.55520936927459],
                  [-28.41438782391578, 38.56126765730894],
                  [-28.38831150986484, 38.55776342920268],
                  [-28.34355005047886, 38.53937507212424],
                  [-28.329668813683686, 38.53633001569091],
                  [-28.308189748134627, 38.522171549596855],
                  [-28.296551380765138, 38.507404663313714],
                  [-28.269483651574216, 38.499149222410445],
                  [-28.24070911014654, 38.49803386455824],
                  [-28.232197272414965, 38.48721949187277],
                  [-28.200887752991225, 38.47580690960504],
                  [-28.19156463646117, 38.462600876227626],
                  [-28.13379843760911, 38.450922623651145],
                  [-28.066210583923468, 38.44442888895395],
                ],
              ],
              [
                [
                  [-27.81286657950113, 38.57744838108681],
                  [-27.776447027077996, 38.56425905468454],
                  [-27.75293185787806, 38.548339923009806],
                  [-27.77382689688573, 38.536217925108346],
                  [-27.8290179568931, 38.533668348326664],
                  [-27.866942135611385, 38.552665467736304],
                  [-27.90175886237059, 38.56520373620578],
                  [-27.941874677506103, 38.58914736026355],
                  [-28.008193190562235, 38.600674991995454],
                  [-28.023986119215515, 38.601470715364194],
                  [-28.069918564953625, 38.62798195091431],
                  [-28.10476191540732, 38.63206018003031],
                  [-28.12738299441384, 38.643987480733585],
                  [-28.149148269855296, 38.64967382794654],
                  [-28.19457292438599, 38.66990451666542],
                  [-28.220721970709008, 38.68459339316081],
                  [-28.230525042698947, 38.70142766382869],
                  [-28.26864062061753, 38.71582595430692],
                  [-28.298335713535824, 38.7383797211063],
                  [-28.312075548192844, 38.755667461180515],
                  [-28.262368145336115, 38.7448697517255],
                  [-28.232659350858736, 38.7308498096425],
                  [-28.144042596316073, 38.70615356560716],
                  [-28.11737825178536, 38.6929979419704],
                  [-28.092843413649454, 38.68907546150519],
                  [-28.07845363073369, 38.68116179014049],
                  [-28.03713765635658, 38.67087927434998],
                  [-28.023530859190963, 38.67181925449239],
                  [-27.993275909565156, 38.65772456164837],
                  [-27.97662592745192, 38.64394181877845],
                  [-27.927055024117536, 38.62723660772738],
                  [-27.916050504522385, 38.61571619638687],
                  [-27.90313687258918, 38.614500790012826],
                  [-27.81286657950113, 38.57744838108681],
                ],
              ],
            ],
          },
          properties: { NUTS2: "Região Autónoma dos Açores" },
        },
      ],
    },
  }

  componentDidMount() {}

  render() {
    return (
      <div className="map-container obcrei-background-1">
        <Map
          nuts2={this.state.nuts2}
          madeira={this.state.madeira}
          acores={this.state.acores}
          portugalContinental={this.state.portugalContinental}
          data={this.props.data}
          toggleStatisticsPanelIsOpen={this.props.toggleStatisticsPanelIsOpen}
          setYearSelected={this.props.setYearSelected}
          yearSelected={this.props.yearSelected}
          setIndicatorSelected={this.props.setIndicatorSelected}
          indicatorSelected={this.props.indicatorSelected}
          toggleIndicatorDetailActive={this.props.toggleIndicatorDetailActive}
          regionSelected={this.props.regionSelected}
          indicatorGroupSelected={this.props.indicatorGroupSelected}
          tagSelected={this.props.tagSelected}
          dimensionSelected={this.props.dimensionSelected}
          creativeCycleSelected={this.props.creativeCycleSelected}
          setRegionSelected={this.props.setRegionSelected}
          hoverMenuButtons={this.props.hoverMenuButtons}
          setMouseTooltipVisible={this.props.setMouseTooltipVisible}
          isMouseTooltipVisible={this.props.isMouseTooltipVisible}
          setMouseTooltipNotVisible={this.props.setMouseTooltipNotVisible}
          setYearsAvailableNUTS2={this.props.setYearsAvailabelNUTS2}
        />
      </div>
    )
  }
}
