import React, { Component } from "react"
import "./macro-menu.scss"
import "../../../helpers/styles/typography.scss"
import "../../../helpers/styles/general.scss"
import { setNodeColor } from "../../../helpers/obcrei-helpers"
import _ from "lodash"
import Search from "./search/Search"

const SearchIcon = props => {
  const iconFill = props.iconFill

  return (
    <svg
      className="search-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      aria-labelledby="title"
    >
      <title id="title">Search Icon</title>
      <g>
        <circle id="Oval" fill="#1E1B2F" cx="11" cy="11" r="11" />
        <path
          d="M9.5,15 C6.46243388,15 4,12.5375661 4,9.5 C4,6.46243388 6.46243388,4 9.5,4 C12.5375661,4 15,6.46243388 15,9.5 C15,12.5375661 12.5375661,15 9.5,15 Z M9.5,13 C11.4329966,13 13,11.4329966 13,9.5 C13,7.56700338 11.4329966,6 9.5,6 C7.56700338,6 6,7.56700338 6,9.5 C6,11.4329966 7.56700338,13 9.5,13 Z"
          id="Combined-Shape"
          fill="#FFFFFF"
        />
        <rect
          id="Rectangle"
          fill="#FFFFFF"
          transform="translate(14.292893, 14.792893) rotate(-45.000000) translate(-14.292893, -14.792893) "
          x="13.2928932"
          y="12.2928932"
          width="2"
          height="5"
        />
      </g>
    </svg>
  )
}

// TODO add search component with open menu row
//styling

const MenuRow = props => {
  return (
    <div className="macro-menu-button-row">
      {props.macroCategoryList.map((macro, index) => {
        const buttonStyle = _.includes(props.macroCategoryActive, macro)
          ? { backgroundColor: setNodeColor(macro), color: "black", border: 0 }
          : { borderColor: setNodeColor(macro) }

        return (
          <button
            key={index}
            className="menu-button-obcrei-macro"
            style={buttonStyle}
            onClick={() => {
              var event = new CustomEvent("clickMacroMenuItem", {
                detail: macro,
              })
              window.dispatchEvent(event)
            }}
          >
            {macro}
          </button>
        )
      })}

      <div
        className="macro-menu-close-button"
        onClick={props.toggleMacroMenuIsOpen}
      >
        <svg width="22px" height="22px">
          <circle fill="#1E1B2F" cx="11" cy="11" r="11" />
          <text
            fontFamily="Helvetica"
            fontSize="14"
            fontWeight="normal"
            letterSpacing="-0.21"
            fill="#FFFFFF"
          >
            <tspan x="7" y="15">
              X
            </tspan>
          </text>
        </svg>
      </div>
      <div
        className="search-button"
        onClick={evt => {
          props.toggleSearchIsActive()
          props.searchValueSetter("")
        }}
      >
        <SearchIcon />
      </div>
      <div className="search-box">
        {props.searchIsActive && (
          <div className="search-autcomplete">
            <Search
              data={props.data}
              searchValueSetter={props.searchValueSetter}
              value={props.value}
              changeTagSelected={props.changeTagSelected}
              changeTagSelected={props.changeTagSelected}
              setIndicatorSelected={props.setIndicatorSelected}
              setStatisticsPanelIsOpen={props.setStatisticsPanelIsOpen}
              toggleSearchIsActive={props.toggleSearchIsActive}
            />
          </div>
        )}
      </div>
    </div>
  )
}
export default class MacroMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
    }
  }

  searchValueSetter = value => this.setState({ value })

  render() {
    return (
      <div className="macro-menu-wrapper">
        {this.props.macroMenuIsOpen ? (
          <MenuRow
            macroCategoryList={this.props.macroCategoryList}
            macroCategoryActive={this.props.macroCategoryActive}
            toggleMacroMenuIsOpen={this.props.toggleMacroMenuIsOpen}
            toggleSearchIsActive={this.props.toggleSearchIsActive}
            data={this.props.data}
            searchValueSetter={this.searchValueSetter}
            value={this.state.value}
            changeTagSelected={this.props.changeTagSelected}
            changeTagSelected={this.props.changeTagSelected}
            setIndicatorSelected={this.props.setIndicatorSelected}
            setStatisticsPanelIsOpen={this.props.setStatisticsPanelIsOpen}
            searchIsActive={this.props.searchIsActive}
          />
        ) : (
          <div className="macro-plus-search">
            <button
              className="menu-button"
              onClick={this.props.toggleMacroMenuIsOpen}
            >
              {this.props.macroMenuButtonText}
            </button>
            <div
              className="search-button"
              onClick={evt => {
                this.props.toggleSearchIsActive()
                this.searchValueSetter("")
              }}
            >
              <SearchIcon />
            </div>
            <div className="search-box">
              {this.props.searchIsActive && (
                <div className="search-autcomplete">
                  <Search
                    data={this.props.data}
                    searchValueSetter={this.searchValueSetter}
                    value={this.state.value}
                    changeTagSelected={this.props.changeTagSelected}
                    changeTagSelected={this.props.changeTagSelected}
                    setIndicatorSelected={this.props.setIndicatorSelected}
                    setStatisticsPanelIsOpen={
                      this.props.setStatisticsPanelIsOpen
                    }
                    toggleSearchIsActive={this.props.toggleSearchIsActive}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}
