import React from "react"
import RootDashboardComponent from "../components/obcrei/RootDashboardComponent"
import Layout from "../components/layout"
// import obcrei from "../data/assets/obcrei.svg"
//import Banner from "../components/Banner/banner"
import { graphql } from "gatsby"
import Banner from "../components/Banner/banner2"
import LocalizedLink from "../components/utils/LocalizedLink"

export default ({ pageContext: { locale, currentPath }, data }) => {
  const content = data.childDashboardContentJson
  const obcrei = content.Banner.logotipo.localFile.publicURL
  console.log("🚀 ~ file: obcrei.js:13 ~ obcrei:", obcrei)

  return (
    <Layout locale={locale} currentPath={currentPath}>
      <Banner
        banner={content.Banner.background.localFile}
        text={content.Banner.bannerTitle}
        layer={true}
        logo={obcrei}
        opacityValue={0.8}
      />

      <div className="container" style={{ marginBottom: 100 }}>
        <div
          className="row justify-content-md-center"
          style={{ marginTop: 100, marginBottom: 40 }}>
          <div className=" col-sm-10 col-xs-12 text-default color-primary">
            {content.introDashboard}
          </div>

          <div
            className=" col-sm-10 col-xs-12 text-default color-primary"
            style={{ marginTop: 30 }}>
            <LocalizedLink
              to="/sobre-obcrei"
              locale={locale}
              className="btn btn-creialab text--legend"
              style={{
                color: "#ffffff",
                minWidth: 146,
                height: 38,
                lineHeight: "25px",
              }}>
              {content.knowMoreButton}
            </LocalizedLink>
            <a
              onClick={() => {
                const el = document.getElementById("obcrei-dashboard")
                el.scrollIntoView({ block: "start", behavior: "smooth" })
              }}
              className="btn btn-creialab btn-creialab--border text--legend"
              style={{
                minWidth: 146,
                height: 38,
                lineHeight: "25px",
                marginLeft: 20,
                cursor: "pointer",
              }}>
              {content.goToDashboardButton}
            </a>
          </div>
        </div>
      </div>

      <RootDashboardComponent textData={data} />
    </Layout>
  )
}

export const query = graphql`
  query obcreiDashboard($locale: String!) {
    childDashboardContentJson: strapiObcrei(locale: { eq: $locale }) {
      Banner {
        bannerTitle
        background {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, maxHeight: 294) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        logotipo {
          localFile {
            publicURL
          }
        }
      }
      dotPlotInfoDetail
      dotPlotInfoTitle
      feedbackMessageMapDashboard
      feedbackMessagePanelButtons
      feedbackMobileView
      goToDashboardButton
      introDashboard
      knowMoreButton
      locale
      macroMenuButton
      mainTitle
      mapButton
      openDashboardButton
      statisticsPanelButton
      titleThird
      titleSecond
      titleIndicators
      titleFourth
      titleFirst
      titleFifth
      textContentFifth {
        data {
          textContentFifth
        }
      }
      textContentFirst {
        data {
          textContentFirst
        }
      }
      textContentFirstList {
        data {
          textContentFirstList
        }
      }
      textContentFourth {
        data {
          textContentFourth
        }
      }
      textContentFourthList {
        data {
          textContentFourthList
        }
      }
      textContentIndicators {
        data {
          textContentIndicators
        }
      }
      textContentSecond {
        data {
          textContentSecond
        }
      }
      textContentThird {
        data {
          textContentThird
        }
      }
      textContentThirdList {
        data {
          textContentThirdList
        }
      }
      circularMenuButton
    }
  }
`

// export const obcreiContent = graphql`
//   query($locale: String) {
//     file(
//       name: { eq: $locale }
//       relativeDirectory: { eq: "textContent/dashboardContent" }
//     ) {
//       childDashboardContentJson {
//         bannerImage {
//           childImageSharp {
//             fluid(maxWidth: 1200, maxHeight: 294) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//         macroMenuButton
//         mapButton
//         statisticsPanelButton
//         circularMenuButton
//         bannerText
//         introDashboard
//         goToDashboardButton
//         knowMoreButton
//         titleIndicators
//         textContentIndicators
//         openDashboardButton
//         mainTitle
//         titleFirst
//         textContentFirst
//         textContentFirstList
//         titleSecond
//         textContentSecond
//         titleThird
//         textContentThird
//         textContentThirdList
//         titleFourth
//         textContentFourth
//         textContentFourthList
//         titleFifth
//         textContentFifth
//         feedbackMessagePanelButtons
//         feedbackMessageMapDashboard
//         feedbackMobileView
//         dotPlotInfoTitle
//         dotPlotInfoDetail
//       }
//     }
//   }
// `
