import React, { Component } from "react"
import "../../../helpers/styles/general.scss"
import "./indicator-groups-menu.scss"

export default class IndicatorGroupsMenu extends Component {
  state = {
    dataGroups: [],
  }

  componentDidMount() {
    const filteredData = this.props.data && this.props.data.length && this.props.data.filter(
      row =>
        row.node.tag1 === this.props.tagSelected &&
        this.props.regionSelected.includes(row.node.escalaMicro)
    )

    let rawData = []
    filteredData.forEach(node => {
      const row = node.node

      if (row.tagGroup && !rawData.includes(row.tagGroup)) {
        rawData = [...rawData, row.tagGroup]
      }
    })

    this.setState({ dataGroups: rawData })

    if (
      rawData.length &&
      !rawData.includes(this.props.indicatorGroupSelected)
    ) {
      this.props.setIndicatorGroupSelected(rawData[0])
    } else {
      this.props.setIndicatorGroupSelected(null)
    }
  }

  render() {
    let indicatorGroupSelected = null

    this.props.indicatorGroupSelected
      ? (indicatorGroupSelected = this.props.indicatorGroupSelected)
      : (indicatorGroupSelected = 1)

    return (
      <div className="indicator-groups-menu-wrapper">
        {this.state.dataGroups.map((d, i) => {
          let groupSelected =
            d === indicatorGroupSelected ? "indicator-group-selected" : ""

          return (
            <button
              key={i}
              className={"menu-button indicator-group-button " + groupSelected}
              onClick={() => this.props.setIndicatorGroupSelected(d)}
            >
              Grupo {d}
            </button>
          )
        })}
      </div>
    )
  }
}
