import React from "react"
import * as d3 from "d3"
import Node from "./Node"
import Link from "./Link"
import _ from "lodash"

import "./graph.scss"
import ResponsiveWrapper from "../../utils/ResponsiveWrapper"
import { ConsoleView } from "react-device-detect"

// @Rui as bolas estão a sair do viewport do svg e não estavam antes. mexeste nalguma coisa para ter esta parte mais responsiva ?
//limit graph dimensions so it isn't covered by panels
// diferenciar os niveis

var FORCE = null

FORCE = (function(nsp) {
  var width = null
  var height = null //window.innerHeight - 100.25

  var previousNodes = null
  var nodeClicked = null
  var clickedNodes = []
  var macroCategoryActive = null
  var previousMacroCategoryActive = ["Indústrias Criativas"]
  var currentNodes = null
  var currentTagSelected = null
  var previousTagSelected = null
  //node dimensions

  var nodeWidth = node => {
    let width = null

    switch (node.group) {
      case 0:
        width = 38
        break
      case 1:
        width = 68
        break
      case 2:
        width = 98
        break
      case 3:
        width = 142
        break

      default:
        width = 197 //root node
        break
    }

    return width / 2
  }

  var initForce = (
      nodes,
      links,
      widthState,
      datasetSelected,
      containerHeight,
      activeMacroCategories,
      tagSelected,
      prevState,
      prevProps,
      key
    ) => {
      width = widthState

      if (width <= 991) {
        height = containerHeight - 60.25
      } else {
        height = containerHeight - 100.25
      }

      height = containerHeight

      currentNodes = nodes

      if (prevState && prevState.nodes !== nodes)
        previousNodes = prevState.nodes

      currentTagSelected = tagSelected

      if (prevState && prevState.tagSelected !== tagSelected)
        previousTagSelected = prevState.tagSelected

      macroCategoryActive = activeMacroCategories

      // calculate scales for clustering nodes

      const x = d3
        .scaleOrdinal()
        .domain([
          "Património",
          "Criações Funcionais",
          "Média",
          "Indústrias Criativas",
          "Práticas criativas e culturais em geral",
          "Artes",
        ])
        .range([
          0.1 * width,
          0.25 * width,
          0.5 * width,
          0.5 * width,
          0.5 * width,
          0.75 * width,
        ])

      const y = d3
        .scaleOrdinal()
        .domain([
          "Património",
          "Criações Funcionais",
          "Média",
          "Indústrias Criativas",
          "Artes",
          "Práticas criativas e culturais em geral",
        ])
        .range([
          0.33 * height,
          0.33 * height,
          0.33 * height,
          0.5 * height,
          0.5 * height,
          0.66 * height,
          0.66 * height,
        ])

      //instantiate force simulation

      const dataset1Conf = {
        chargeStrength: -5000,
        alphaDecay: 0.02,
      }

      const dataset2Conf = {
        chargeStrength: -100,
        alphaDecay: 0.03,
      }

      let conf = {}

      if (datasetSelected === 1) {
        conf = dataset1Conf
      } else {
        conf = dataset2Conf
      }

      nsp.force = d3
        .forceSimulation(nodes)
        .force("charge", d3.forceManyBody().strength(conf.chargeStrength))
        .force(
          "link",
          d3
            .forceLink(links)
            .distance(d => {
              if (
                (d.source.group === 1 && datasetSelected === 1) ||
                (datasetSelected === 2 &&
                  d.source.group === 1 &&
                  !_.includes(macroCategoryActive, d.source.macroCategory))
              ) {
                return nodeWidth(d.source) + nodeWidth(d.target) * 2
              } else {
                return nodeWidth(d.source) + nodeWidth(d.target) / 2
              }
            })
            .strength(d => 0.75)
        )
        .force(
          "x",
          d3
            .forceX()
            .strength(0.2)
            .x(d => {
              return x(d.macroCategory)
            })
        )
        .force(
          "y",
          d3
            .forceY()
            .strength(0.4)
            .y(d => y(d.macroCategory))
        )
        .force(
          "center",
          d3
            .forceCenter()
            .x(width / 2)
            .y(height / 2)
        )
        //.force("collide", d3.forceCollide())
        .force(
          "collide",
          d3
            .forceCollide()
            //.iterations([2])
            .radius(d => {
              return d.radius + 10
            })
          //.strength([0.8])
        )
        .alphaDecay(conf.alphaDecay)
    },
    //update nodes

    updateNode = selection => {
      let clickedNodesNames = []
      clickedNodes.map(d => clickedNodesNames.push(d.name))

      selection
        .attr("transform", d => {
          if (clickedNodes.length === 1) {
            previousNodes.map(j => {
              if (j.name === d.name && nodeClicked.name === d.name) {
                d.fx = j.x
                d.fy = j.y
              }
            })
          } else {
            previousNodes &&
              clickedNodes &&
              previousNodes.map(j => {
                if (
                  (_.includes(clickedNodesNames, d.name) || d.nodeClicked) &&
                  _.includes(macroCategoryActive, j.macroCategory) &&
                  d.group !== 3 &&
                  j.name === d.name
                ) {
                  d.fx = j.x
                  d.fy = j.y
                }
              })
          }

          // if (
          //   currentNodes.length !== previousNodes.length &&
          //   previousTagSelected !== currentTagSelected //&&
          //previousTagSelected !== previousTagSelected
          // ) {
          //   console.log("ENTREI NO 1")

          // } else {

          //}

          return "translate(" + d.x + "," + d.y + ")"
        })
        .attr("cx", function(d) {
          return (d.x = Math.max(60, Math.min(width - 60, d.x))) ////change here for increase distance to border of svg
        })
        .attr("cy", function(d) {
          return (d.y = Math.max(60, Math.min(height - 60, d.y)))
        })
    },
    //update links

    updateLink = selection => {
      selection
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y)
    },
    //update nodes and links

    updateGraph = selection => {
      selection.selectAll(".node").call(updateNode)
      selection.selectAll(".link").call(updateLink)
    },
    //drag events

    dragStarted = d => {
      if (!d3.event.active) nsp.force.alphaTarget(0.3).restart()
      d.fx = d.x
      d.fy = d.y
    },
    dragging = d => {
      d.fx = d3.event.x
      d.fy = d3.event.y
    },
    dragEnded = d => {
      if (!d3.event.active) nsp.force.alphaTarget(0)
      d.fx = null
      d.fy = null
    },
    drag = () =>
      d3.selectAll("g.node").call(
        d3
          .drag()
          .on("start", dragStarted)
          .on("drag", dragging)
          .on("end", dragEnded)
      ),
    //ticker of the simulation

    tick = that => {
      that.d3Graph = d3.select(that)
      nsp.force.on("tick", () => {
        that.d3Graph.call(updateGraph)
      })
    },
    click = () => {
      d3.selectAll("g.node").on("click", d => {
        nodeClicked = d
        clickedNodes.push(nodeClicked)
      })
    },
    stop = () => {
      nsp.force.stop()
    },
    simulationEnded = () => {
      //console.log("END SIMULATION")
    },
    listenEnd = () => {
      nsp.force.on("end", simulationEnded)
    },
    restart = () => {
      nsp.force.alphaTarget(0.3).restart()
    }

  nsp.width = width
  nsp.height = height
  nsp.nodeWidth = nodeWidth
  nsp.updateNode = updateNode

  nsp.updateLink = updateLink
  nsp.updateGraph = updateGraph
  nsp.initForce = initForce
  nsp.dragStarted = dragStarted
  nsp.dragging = dragging
  nsp.dragEnded = dragEnded
  nsp.drag = drag
  nsp.tick = tick
  nsp.stop = stop
  nsp.click = click
  nsp.simulationEnded = simulationEnded
  nsp.listenEnd = listenEnd
  nsp.restart = restart

  return nsp
})(FORCE || {})

class Graph extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nodes: [],
      links: [],
      parentWidth: null,
      datasetSelected: 1,
      containerHeight: null,
      initialUpdateDone: false,
      nodeIdClicked: null,
      uKey: 1,
    }

    this.graph = React.createRef()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      nodes,
      links,
      parentWidth,
      datasetSelected,
      containerHeight,
      nodeIdClicked,
      macroCategoryActive,
      tagSelected,
    } = nextProps
    if (!nodes || !links) return {}

    return {
      nodes,
      links,
      parentWidth,
      datasetSelected,
      containerHeight,
      nodeIdClicked,
      macroCategoryActive,
      tagSelected,
    }
  }

  componentDidMount() {
    FORCE.initForce(
      this.state.nodes,
      this.state.links,
      this.state.parentWidth,
      this.props.datasetSelected,
      this.state.containerHeight,
      this.state.macroCategoryActive,
      this.state.tagSelected
    )

    FORCE.tick(this.graph.current)
    FORCE.drag()
    FORCE.click()
    FORCE.listenEnd()
  }

  componentDidUpdate(prevProps, prevState) {
    FORCE.initForce(
      this.state.nodes,
      this.state.links,
      this.state.parentWidth,
      this.props.datasetSelected,
      this.state.containerHeight,
      this.state.macroCategoryActive,
      this.state.tagSelected,
      prevProps,
      prevState
    )

    if (this.state.datasetSelected === 2) FORCE.tick(this.graph.current)

    FORCE.drag()
    FORCE.click()
    FORCE.listenEnd()
  }

  render() {
    var nodeSelected = this.state.nodes.filter(
      node => this.props.tagSelected && node.name === this.props.tagSelected
    )[0]

    var links = this.state.links.map(link => {
      return <Link key={link.id} data={link} />
    })
    var nodes = this.state.nodes.map(node => {
      node.radius = FORCE.nodeWidth(node)

      return (
        <Node
          data={node}
          nodeSelected={nodeSelected}
          name={node.name}
          key={node.id}
          group={node.group}
          parent={node.parent}
          macroCategory={node.macroCategory}
          handleClickOnNode={this.props.handleClickOnNode}
          tagSelected={this.props.tagSelected}
          datasetSelected={this.props.datasetSelected}
          toggleStatisticsPanelIsOpen={this.props.toggleStatisticsPanelIsOpen}
          toggleMapPanelIsOpen={this.props.toggleMapPanelIsOpen}
          toggleMacroMenuIsOpen={this.props.toggleMacroMenuIsOpen}
          setStatisticsPanelIsOpen={this.props.setStatisticsPanelIsOpen}
          macroCategoryActive={this.state.macroCategoryActive}
        />
      )
    })

    return (
      <div className="graph__wrapper" ref={this.graph}>
        <svg
          className="graph"
          width={this.state.parentWidth}
          height={this.state.containerHeight}
          key={this.state.uKey}>
          <g>{links}</g>
          <g>{nodes}</g>
        </svg>
      </div>
    )
  }
}

export default ResponsiveWrapper(Graph)
