import React, { Component } from "react"
import * as d3 from "d3"
import * as d3Selection from "d3-selection"
import "d3-extended" // d3Selection is now extended
import { legendColor } from "d3-svg-legend"
import Select, { components } from "react-select"
import "./map.scss"
import "../../../helpers/styles/typography.scss"
import map from "lodash/fp/map"
import flow from "lodash/fp/flow"

import { isNullOrUndefined } from "util"
import _ from "lodash"

//const margin = { top: 18, right: 11, bottom: 18, left: 11 }
const width = 588
const height = 329

export default class Map extends Component {
  constructor(props) {
    super(props)
    this.state = { data: [] }
    this.indicator = null
    this.year = null
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let filteredData = nextProps.data.filter(
      row => row.node.tag1 === nextProps.tagSelected
    )

    if (nextProps.indicatorGroupSelected) {
      filteredData = filteredData.filter(
        row => row.node.tagGroup === nextProps.indicatorGroupSelected
      )
    }

    if (nextProps.dimensionSelected) {
      filteredData = filteredData.filter(row => {
        return (
          row.node.dimensao === nextProps.dimensionSelected.replace(" ", "/")
        )
      })
    }

    if (nextProps.creativeCycleSelected) {
      filteredData = filteredData.filter(
        row =>
          row.node.cicloCriativo ===
          nextProps.creativeCycleSelected.replace(" ", "/")
      )
    }

    let rawData = []
    filteredData.forEach(node => {
      const row = node.node,
        id = row.indicador + row.escalaMicro
      if (isNullOrUndefined(rawData[id])) {
        rawData[id] = {
          yearValues: [],
          indicador: row.indicador,
          descricao: "",
          fonte: row.fonte,
          estudo: row.estudo,
          escalaMacro: row.industriasCriativasMacro,
          escalaMicro: row.escalaMicro,
          years: [],
          tagGroup: row.tagGroup,
          values: [],
        }
      }

      rawData[id].yearValues[row.temporalidadeAno] = {
        year: row.temporalidadeAno,
        value: row.valor,
      }

      rawData[id].years = [...rawData[id].years, row.temporalidadeAno]
      rawData[id].values = [...rawData[id].values, row.valor]
    })

    rawData = Object.keys(rawData)
      .map(i => rawData[i])
      .map(row => {
        row.yearValues = Object.keys(row.yearValues).map(i => row.yearValues[i])
        return row
      })

    let years = []
    rawData.map(row => {
      if (row.escalaMicro !== "Portugal") years = [...years, ...row.years]
    })

    return {
      data: rawData,
      indicadores: _.uniqBy(rawData, "indicador"),
      years: _.sortBy(_.uniq(years)),
    }
  }

  componentDidMount() {
    this.renderMap()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.regionSelected.length === 1 &&
      !this.props.regionSelected.includes("Portugal") &&
      this.props.isMouseTooltipVisible === false
    ) {
      this.props.setMouseTooltipVisible("map")
    }

    if (this.props.regionSelected !== prevProps.regionSelected) {
      if (
        this.props.regionSelected.length !== 1 ||
        this.props.regionSelected.includes("Portugal")
      ) {
        this.props.setMouseTooltipNotVisible()
      }
    }

    if (this.props.tagSelected !== prevProps.tagSelected) {
      if (!_.includes(this.state.years, this.props.yearSelected))
        this.props.setYearSelected(
          this.state.years[this.state.years.length - 1]
        )
    }

    this.renderMap()
  }

  renderMap() {
    if (this.props.indicatorSelected) {
      this.indicator = this.props.indicatorSelected
    } else {
      if (this.state.indicadores[0]) {
        this.indicator = this.state.indicadores[0]?.indicador
        this.props.setIndicatorSelected(this.state.indicadores[0]?.indicador)
      }
    }

    if (this.state.indicadores[0]) {
      if (
        !_.find(this.state.indicadores, {
          indicador: this.props.indicatorSelected,
        })
      ) {
        this.indicator = this.state.indicadores[0]?.indicador
        this.props.setIndicatorSelected(this.state.indicadores[0]?.indicador)
      }
    }

    if (this.props.yearSelected) {
      this.year = this.props.yearSelected
    } else {
      this.year = this.state.years[this.state.years.length - 1]
      this.props.setYearSelected(this.year)
    }

    //const geojsonPt = this.props.portugalContinental;
    //this.props.nuts2;
    const geojsonMadeira = this.props.madeira
    const geojsonAcores = this.props.acores
    const nuts2 = this.props.nuts2

    // create map configurations

    const projectionPt = d3
        .geoMercator()
        .scale(2500)
        .center([0, 38]),
      geoGeneratorPt = d3.geoPath().projection(projectionPt),
      projectionAcores = d3
        .geoMercator()
        .scale(1300)
        .center([0, 38]),
      geoGeneratorAcores = d3.geoPath().projection(projectionAcores),
      projectionMadeira = d3
        .geoMercator()
        .scale(1800)
        .center([0, 38]),
      geoGeneratorMadeira = d3.geoPath().projection(projectionMadeira)

    // Join the FeatureCollection's features array to path elements
    /*
    d3.select (this.refs.portugal).selectAll ('path').remove ();
    const pt = d3
      .select (this.refs.portugal)
      .selectAll ('path')
      .data (geojsonPt.features);

      */
    d3.select(this.refs.madeira)
      .selectAll("path")
      .remove()
    const m = d3
      .select(this.refs.madeira)
      .selectAll("path")
      .data(geojsonMadeira.features)

    d3.select(this.refs.acores)
      .selectAll("path")
      .remove()
    const a = d3
      .select(this.refs.acores)
      .selectAll("path")
      .data(geojsonAcores.features)

    // Create path elements and update the d attribute using the geo generator

    d3.select(this.refs.portugal_nuts)
      .selectAll("path")
      .remove()
    const ptn = d3
      .select(this.refs.portugal_nuts)
      .selectAll("path")
      .data(nuts2.features)

    /*
    pt.enter()
      .append("path")
      .attr("class", "portugal")
      .attr("d", geoGeneratorPt)

*/

    m.enter()
      .append("path")
      .attr("d", geoGeneratorMadeira)

    a.enter()
      .append("path")
      .attr("d", geoGeneratorAcores)

    let tooltip = d3
      .select(".map-component")
      .append("div")
      .attr("class", "tooltip typo-body-2-white")
      .style("opacity", 0)

    let feedbackTooltip = d3.select(".feedback-tooltip")

    let mouseOver = function(d, i, _this, that) {
      d3.select(_this[i])
        .transition()
        .duration(200)
        .style("opacity", 1)
        .style("stroke", "#FF3E25")
        .style("stroke-width", "2")
        .style("cursor", "pointer")

      tooltip
        .transition()
        .duration(200)
        .style("opacity", 1)
      feedbackTooltip
        .transition()
        .duration(200)
        .style("opacity", 1)

      var valuesByNut = that.state.data.filter(row => {
        return row.escalaMicro === d["properties"].NUTS2
      })

      var valuesByNutByIndicator = valuesByNut.filter(row => {
        return row.indicador === that.indicator
      })

      var valuesByNutByIndicatorByYear = valuesByNutByIndicator[0]?.yearValues.filter(
        row => {
          return +row.year == that.props.yearSelected
        }
      )

      if (d["properties"].NUTS2 === "Região Autónoma da Madeira") {
        tooltip
          .html(
            "<strong>" +
              "R.A. da Madeira" +
              "</strong>" +
              "<br />" +
              valuesByNutByIndicatorByYear[0]?.value
          )
          .style("left", geoGeneratorMadeira.centroid(d)[0] + 240 + "px")
          .style("top", geoGeneratorMadeira.centroid(d)[1] - 200 + "px")
      } else if (d["properties"].NUTS2 === "Região Autónoma dos Açores") {
        tooltip
          .html(
            "<strong>" +
              "R.A dos Açores" +
              "</strong>" +
              "<br />" +
              valuesByNutByIndicatorByYear[0]?.value
          )
          .style("left", geoGeneratorAcores.centroid(d)[0] + 240 + "px")
          .style("top", geoGeneratorAcores.centroid(d)[1] - 180 + "px")
      } else {
        tooltip
          .html(
            "<strong>" +
              d["properties"].NUTS2 +
              "</strong>" +
              "<br />" +
              valuesByNutByIndicatorByYear[0]?.value
          )
          .style("left", geoGeneratorPt.centroid(d)[0] + 150 + "px")
          .style("top", geoGeneratorPt.centroid(d)[1] - 10 + "px")
      }
    }

    let mouseLeave = function(d, i, _this, that) {
      d3.select(_this[i])
        .transition()
        .duration(200)
        .style("opacity", 1)
        .style("stroke", d => {
          return that.props.regionSelected.includes(d["properties"].NUTS2)
            ? "#FF3E25"
            : "rgba(0,0,0,.2)"
        })

      tooltip
        .transition()
        .duration(200)
        .style("opacity", 0)
      feedbackTooltip
        .transition()
        .duration(200)
        .style("opacity", 0)
    }

    var indicatorSelected = this.state.data.filter(
      row => row.indicador == this.indicator
    )

    indicatorSelected.shift() //remove whole country values

    let allValues = []

    indicatorSelected.map(row => allValues.push(row.values))
    var flattenedAllValues = _.flattenDeep(allValues)

    var domain = [d3.min(flattenedAllValues), d3.max(flattenedAllValues)] // domain for color scale

    var colorGenerator = d3
      .scaleLinear()
      .domain([0, 7])
      .range(["#E5D1E8", "#692F72"])
      .interpolate(d3.interpolateHcl)

    var quantScale = d3
      .scaleQuantize()
      .domain(domain)
      .range(d3.range(7).map(colorGenerator))

    //use d3-legend d3-legend.susielu.com

    var legendQuant = legendColor()
      .shapeWidth(14)
      .shapeHeight(14)
      .shapePadding(0)
      .cells(7)
      .labelFormat(d3.format(".2s"))
      .orient("vertical")
      .scale(quantScale)
      .labelDelimiter("-")

    d3.select(this.refs.legend).call(legendQuant)

    let regions = this.props.regionSelected.filter(
      region => region != "Portugal"
    )

    ptn
      .enter()
      .append("path")
      .attr("d", geoGeneratorPt)
      .attr("class", "ptn")
      .on("mouseover", (d, i, _this) => {
        mouseOver(d, i, _this, this)
      })
      .on("mouseleave", (d, i, _this) => mouseLeave(d, i, _this, this))
      .on("click", d => {
        tooltip
          .transition()
          .duration(200)
          .style("opacity", 0)

        let selectRegion = this.state.data.filter(
          row => row.escalaMicro === d.properties.NUTS2
        )

        if (this.props.indicatorGroupSelected) {
          selectRegion = selectRegion.filter(
            row => row.tagGroup === this.props.indicatorGroupSelected
          )
        }
        if (this.indicator) {
          selectRegion = selectRegion.filter(
            row => row.indicador === this.indicator
          )
        } else {
          if (_.isArray(selectRegion)) selectRegion = selectRegion[0]
        }

        if (
          regions.length < 2 &&
          this.props.regionSelected[0] !== d.properties.NUTS2.trim()
        ) {
          return this.props.setRegionSelected([
            ...regions,
            d.properties.NUTS2.trim(),
          ]) //add to regions array if length < 2, if region already there don't add
        }

        regions = this.props.regionSelected.filter(
          region => region.trim() != d.properties.NUTS2.trim()
        )

        if (regions.length === 2) {
          return this.props.setRegionSelected([d.properties.NUTS2.trim()])
        }

        //if === 1 e o click é na região seleccionada region = ["Portugal"]

        if (!regions.length) {
          regions = ["Portugal"]
        }

        return this.props.setRegionSelected(regions)
      })
      .style("fill", d => {
        if (this.props.regionSelected.includes(d.properties.NUTS2)) {
          //STYLE SELECTED
          return "rgba(255,62,37,.6)" //check if region selected matches current datum region
        } else {
          //STYLE NOT SELECTED -> COLOR SCALE

          let total = this.state.data.filter(row => {
            return (
              row.escalaMicro.trim().replace(/ /g, "") ===
              d.properties.NUTS2.replace(/ /g, "")
            )
          })

          // filter dataset by region selected -> repeated on hover ..have to refactor

          if (this.indicator) {
            total = total.filter(row => row.indicador == this.indicator) // filter dataset by indicator selected -> repeated on hover ..have to refactor
          }

          if (this.props.indicatorGroupSelected) {
            total = total.filter(row => {
              return row.tagGroup == this.props.indicatorGroupSelected
            }) // filter dataset by indicator groups selected
          }

          if (total.length) total = total.shift() // remove first element of total if total exists ??

          let colorValue = 0

          // total is all values for a indicator
          if (_.isObject(total) && Object.keys(total).length) {
            if (this.year && total.yearValues) {
              colorValue = total.yearValues.filter(y => {
                return y.year == this.year
              })[0]?.value // all values by region according with year
            } else {
              colorValue = total.yearValues[0]?.value // else default value 2015
            }
          }
          return quantScale(colorValue)
        }
      })
      .style("stroke", d => {
        return this.props.regionSelected.includes(d.properties.NUTS2)
          ? "#FF3E25"
          : "rgba(0,0,0,.2)"
      })

    m.enter()
      .append("path")
      .attr("d", geoGeneratorMadeira)
      .attr("class", "m")
      .on("mouseover", (d, i, _this) => {
        mouseOver(d, i, _this, this)
      })
      .on("mouseleave", (d, i, _this) => mouseLeave(d, i, _this, this))
      .style("fill", d => {
        //refactor to function
        if (this.props.regionSelected.includes(d.properties.NUTS2)) {
          return "rgba(255,62,37,.6)"
        } else {
          let total = this.state.data.filter(row => {
            return (
              row.escalaMicro.trim().replace(/ /g, "") ===
              d.properties.NUTS2.replace(/ /g, "")
            )
          }) // filter dataset by region selected -> repeated on hover ..have to refactor

          if (this.indicator) {
            total = total.filter(row => row.indicador == this.indicator) // filter dataset by indicator selected -> repeated on hover ..have to refactor
          }

          if (this.props.indicatorGroupSelected) {
            total = total.filter(
              row => row.tagGroup == this.props.indicatorGroupSelected
            ) // filter dataset by indicator groups selected
          }

          if (total.length) total = total.shift()

          let colorValue = 0

          // total is all values for a indicator

          if (_.isObject(total) && Object.keys(total).length) {
            if (this.year && total.yearValues) {
              colorValue = total.yearValues.filter(y => y.year == this.year)[0]
                ?.value // all values by region according with year
            } else {
              colorValue = total.yearValues[0]?.value // else default value 2015
            }
          }

          return quantScale(colorValue)
        }
      })
      .on("click", d => {
        tooltip
          .transition()
          .duration(200)
          .style("opacity", 0)

        let selectRegion = this.state.data.filter(
          row => row.escalaMicro === d.properties.NUTS2
        )

        if (this.props.indicatorGroupSelected) {
          selectRegion = selectRegion.filter(
            row => row.tagGroup === this.props.indicatorGroupSelected
          )
        }
        if (this.indicator) {
          selectRegion = selectRegion.filter(
            row => row.indicador === this.indicator
          )
        } else {
          if (_.isArray(selectRegion)) selectRegion = selectRegion[0]
        }

        if (
          regions.length < 2 &&
          this.props.regionSelected[0] !== d.properties.NUTS2.trim()
        ) {
          return this.props.setRegionSelected([
            ...regions,
            d.properties.NUTS2.trim(),
          ]) //add to regions array if length < 2, if region already there don't add
        }

        regions = this.props.regionSelected.filter(
          region => region.trim() != d.properties.NUTS2.trim()
        )

        if (regions.length === 2) {
          return this.props.setRegionSelected([d.properties.NUTS2.trim()])
        }

        //if === 1 e o click é na região seleccionada region = ["Portugal"]

        if (!regions.length) {
          regions = ["Portugal"]
        }

        return this.props.setRegionSelected(regions)
      })

    a.enter()
      .append("path")
      .attr("d", geoGeneratorAcores)
      .attr("class", "a")
      .on("mouseover", (d, i, _this) => {
        mouseOver(d, i, _this, this)
      })
      .on("mouseleave", (d, i, _this) => mouseLeave(d, i, _this, this))
      .style("fill", d => {
        if (this.props.regionSelected.includes(d.properties.NUTS2)) {
          return "rgba(255,62,37,.6)"
        } else {
          let total = this.state.data.filter(
            row =>
              row.escalaMicro.trim().replace(/ /g, "") ===
              d.properties.NUTS2.replace(/ /g, "")
          ) // filter dataset by region selected -> repeated on hover ..have to refactor

          if (this.indicator) {
            total = total.filter(row => row.indicador == this.indicator) // filter dataset by indicator selected -> repeated on hover ..have to refactor
          }

          if (this.props.indicatorGroupSelected) {
            total = total.filter(
              row => row.tagGroup == this.props.indicatorGroupSelected
            ) // filter dataset by indicator groups selected
          }

          if (total.length) total = total.shift()

          let colorValue = 0

          // total is all values for a indicator

          if (_.isObject(total) && Object.keys(total).length) {
            if (this.year && total.yearValues) {
              colorValue = total.yearValues.filter(y => y.year == this.year)[0]
                ?.value // all values by region according with year
            } else {
              colorValue = total.yearValues[0]?.value // else default value 2015
            }
          }
          //color value - values for each region

          return quantScale(colorValue)
        }
      })
      .on("click", d => {
        tooltip
          .transition()
          .duration(200)
          .style("opacity", 0)

        let selectRegion = this.state.data.filter(
          row => row.escalaMicro === d.properties.NUTS2
        )

        if (this.props.indicatorGroupSelected) {
          selectRegion = selectRegion.filter(
            row => row.tagGroup === this.props.indicatorGroupSelected
          )
        }
        if (this.indicator) {
          selectRegion = selectRegion.filter(
            row => row.indicador === this.indicator
          )
        } else {
          if (_.isArray(selectRegion)) selectRegion = selectRegion[0]
        }

        if (
          regions.length < 2 &&
          this.props.regionSelected[0] !== d.properties.NUTS2.trim()
        ) {
          return this.props.setRegionSelected([
            ...regions,
            d.properties.NUTS2.trim(),
          ]) //add to regions array if length < 2, if region already there don't add
        }

        regions = this.props.regionSelected.filter(
          region => region.trim() != d.properties.NUTS2.trim()
        )

        if (regions.length === 2) {
          return this.props.setRegionSelected([d.properties.NUTS2.trim()])
        }

        //if === 1 e o click é na região seleccionada region = ["Portugal"]

        if (!regions.length) {
          regions = ["Portugal"]
        }

        return this.props.setRegionSelected(regions)
      })
  }

  render() {
    //data for <Select /> options

    const yearsOptions = flow(
      map(d => {
        return {
          value: d,
          label: d.toString(),
        }
      })
    )(this.state.years)

    const yearOptionsSelected = yearsOptions.filter(
      option => option.value == this.props.yearSelected
    )[0]

    const indicatorsOptions = flow(
      map(d => {
        return {
          value: d.indicador,
          label: d.indicador,
        }
      })
    )(this.state.indicadores)

    const indicatorOptionsSelected = indicatorsOptions.filter(
      option => option.value === this.props.indicatorSelected
    )[0]

    //styles for <Select />

    const customStyles = {
      option: (provided, state) => {
        return {
          ...provided,
          paddingLeft: "21px",
          color: "#060606",
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "#F6EDF7",
            cursor: "pointer",
          },
        }
      },
      control: (provided, state) => ({
        ...provided,
        backgroundColor: "transparent",
        border: "none",
        border: state.isFocused ? 0 : 0,
        // This line disable the blue border
        boxShadow: state.isFocused ? 0 : 0,
        "&:hover": {
          border: state.isFocused ? 0 : 0,
        },
        minHeight: 0,
      }),
      singleValue: (provided, state) => {
        return {
          ...provided,
          color: state.selectProps.menuIsOpen ? "#ff3e25" : "#d6d4e3",
          paddingLeft: "9px",
          margin: "0",
        }
      },
      indicatorsContainer: provided => ({
        ...provided,
        display: "none",
      }),
      indicatorSeparator: provided => ({
        display: "none",
      }),
      menu: provided => ({
        ...provided,
        backgroundColor: "transparent",
      }),
      menuList: provided => ({
        ...provided,
        backgroundColor: "rgba(214,212,227,.95)",
        borderRadius: "10px",
      }),
    }

    return (
      <div className="map-component">
        <div
          className="filterOptions"
          style={{ position: "absolute", left: 30, bottom: 0, width: 137 }}>
          <div className="filter-map-select select-year">
            <label
              className="typo-body-2-white-bold"
              style={{ display: "block" }}>
              Ano
            </label>
            <div className="select-field">
              <Select
                options={yearsOptions}
                //defaultValue={yearOptionsSelected}
                value={yearOptionsSelected}
                onChange={value => this.props.setYearSelected(value.value)}
                styles={customStyles}
                className={"typo-body-2-chart"}
                isClearable={false}
                isSearchable={false}
                components={{ ValueContainer }}
              />
            </div>
          </div>
          <div className="filter-map-select select-indicador">
            <label
              className="typo-body-2-white-bold"
              style={{ display: "block" }}>
              Indicador
            </label>
            <div className="select-field">
              <Select
                options={indicatorsOptions}
                value={indicatorOptionsSelected}
                onChange={value => this.props.setIndicatorSelected(value.value)}
                styles={customStyles}
                className={"typo-body-2-chart"}
                isClearable={false}
                isSearchable={false}
                components={{ ValueContainer }}
                //menuIsOpen={true}
              />
            </div>
          </div>
        </div>
        <svg width={width} height={height}>
          <g ref="portugal_nuts" transform={`translate(${300}, ${5})`} />
          <g ref="madeira" transform={`translate(${370}, ${-140})`} />
          <g ref="acores" transform={`translate(${350}, ${-80})`} />
          <g
            className="legend"
            ref="legend"
            transform={`translate(${500}, ${220})`}
          />
        </svg>
      </div>
    )
  }
}

//component to feed in <Select /> form react-select - adds arrow to value field

const ValueContainer = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props} className="map-value-container">
        <div className="map-select-arrow">
          <svg width="6px" height="4px">
            <g transform="translate(-45.000000, -441.000000)" fill="#D6D4E3">
              <polygon points="45 441 51 441 48 445" />
            </g>
          </svg>
        </div>
        <div className="map-select-value">{children}</div>
      </components.ValueContainer>
    )
  )
}
