import React, { useState } from "react"
import BarChartContainer from "./barChart/BarChartContainer"
import DotPlotContainer from "./dotPlot/DotPlotContainer"
import IndicatorGroupsMenu from "./IndicatorGroupsMenu"
import "./statistics-panel.scss"

const StatisticsPanel = props => {
  return (
    <>
      {props.indicatorDetailActive && !props.circularMenuIsOpen ? (
        <div className="statistics-group-wrapper">
          <div className="indicator-groups-menu-container">
            <IndicatorGroupsMenu
              indicatorGroupSelected={props.indicatorGroupSelected}
              setIndicatorGroupSelected={props.setIndicatorGroupSelected}
            />
          </div>
          <div className="bar-chart-container obcrei-background-1">
            <BarChartContainer
              data={props.data}
              setYearSelected={props.setYearSelected}
              yearSelected={props.yearSelected}
              toggleIndicatorDetailActive={props.toggleIndicatorDetailActive}
              regionSelected={props.regionSelected}
              dimensionSelected={props.dimensionSelected}
              creativeCycleSelected={props.creativeCycleSelected}
              indicatorGroupSelected={props.indicatorGroupSelected}
              tagSelected={props.tagSelected}
              indicatorSelected={props.indicatorSelected}
            />
          </div>
        </div>
      ) : (
        <div className="statistics-group-wrapper">
          <div className="indicator-groups-menu-container">
            <IndicatorGroupsMenu
              data={props.data}
              indicatorGroupSelected={props.indicatorGroupSelected}
              setIndicatorGroupSelected={props.setIndicatorGroupSelected}
              regionSelected={props.regionSelected}
              tagSelected={props.tagSelected}
            />
          </div>
          <div className="dot-plot-container  obcrei-background-1">
            <DotPlotContainer
              data={props.data}
              toggleStatisticsPanelIsOpen={props.toggleStatisticsPanelIsOpen}
              setYearSelected={props.setYearSelected}
              yearSelected={props.yearSelected}
              setIndicatorSelected={props.setIndicatorSelected}
              indicatorSelected={props.indicatorSelected}
              toggleIndicatorDetailActive={props.toggleIndicatorDetailActive}
              regionSelected={props.regionSelected}
              indicatorGroupSelected={props.indicatorGroupSelected}
              tagSelected={props.tagSelected}
              dimensionSelected={props.dimensionSelected}
              creativeCycleSelected={props.creativeCycleSelected}
              toggleCircularMenuIsOpen={props.toggleCircularMenuIsOpen}
              infoDotPlotTitle={props.textData.dotPlotInfoTitle}
              infoDotPlotDetail={props.textData.dotPlotInfoDetail}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default StatisticsPanel
