import React, { memo } from "react"
import * as d3 from "d3"
import "./node.scss"
import { setNodeColor } from "../../../helpers/obcrei-helpers"
import _ from "lodash"
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider"

const enterNode = selection => {
  selection
    .select(".selected-circle")
    .attr("r", d => d.radius)
    .style("fill", d => {
      return "#393e46"
    })
    .style("stroke", d => setNodeColor(d["macroCategory"]))
    .style("stroke-width", 0)
    .style("box-shadow", "0 2px 4px 0 rgba(0, 0, 0, 0.5)")

  selection
    .select("circle")
    .attr("r", d => d.radius)
    .style("fill", d => {
      return "#393e46"
    })
    .style("stroke", d => setNodeColor(d["macroCategory"]))
    .style("stroke-width", "2px")
    .style("box-shadow", "0 2px 4px 0 rgba(0, 0, 0, 0.5)")
    .style("cursor", "pointer")

  selection
    .select(".path-highlighted")
    .attr("r", d => d.radius)
    .style("fill", d => setNodeColor(d["macroCategory"]))
    .style("opacity", "0.59")
    .style("stroke", "black")

  selection
    .select(".overlay-circle")
    .attr("r", d => d.radius)
    .style("fill", d => setNodeColor(d["macroCategory"]))
    .style("opacity", "0.59")
    .style("stroke-width", "13px")
    .style("stroke-opacity", "0.35")
    .style("stroke", d => setNodeColor(d["macroCategory"]))

  //@Rui o bug dos wraps dos nós é quando se clicka uma segunda vez num nó do mesmo grupo

  selection
    .select("text")
    .style("fill", "#D6D4E3")
    .style("font-weight", "600")
    .style("text-anchor", "middle")
    .style("alignment-baseline", "middle")
    .style("font-size", d => {
      var size

      switch (d.group) {
        case 1:
          size = "10px"
          break
        case 2:
          size = "12px"
          break
        case 3:
          size = "14px"
          break
        case 4:
          size = "16px"
          break
        default:
          return
      }

      return size
    })
    .style("font-family", "Open Sans")
    .style("cursor", "pointer")
    .style("line-spacing", "-0.15")
    .style("line-height", "14")
    .attr("r", d => d.radius + 5)
    .attr("transform", d => {
      if (d.name === "Espetáculos ao Vivo") {
        return "translate(0,0)"
      } else if (d.name.split(/\s+/).length > 3) {
        return "translate(0, -8)"
      }
      return null
    })
    .call(wrap)
}

//Mike Bostock wrap function
function wrap(text) {
  var width = text.attr("r") * 2 - 10

  text.each(function(datapoint) {
    var text = d3.select(this),
      words = datapoint.name.split(/\s+/).reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.2, // ems
      x = text.attr("x"),
      y = text.attr("y"),
      dy = text.attr("dy") ? text.attr("dy") : 0,
      dx = text.attr("dx") ? text.attr("dx") : 0,
      tspan = text
        .text(null)
        .append("tspan")
        .attr("x", x)
        .attr("y", y)
        .attr("dy", dy + "em")
        .attr("dx", dx)

    while ((word = words.pop())) {
      line.push(word)
      tspan.text(line.join(" "))

      if (tspan.node().getComputedTextLength() > width) {
        line.pop()
        tspan.text(line.join(" "))
        line = [word]

        tspan = text
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          //.attr("dx", -tspan.node().getComputedTextLength() + 1)
          .attr("dy", lineHeight + dy + "em")
          .attr("dy", lineHeight + dy + "em")
          .text(word)
      }
    }
  })
}

function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ")
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(" ")
}

class Node extends React.Component {
  constructor(props) {
    super(props)
    this.node = React.createRef()
  }

  componentDidMount() {
    this.d3Node = d3
      .select(this.node.current)
      .datum(this.props.data)
      .call(enterNode)
  }

  componentDidUpdate() {
    this.d3Node = d3
      .select(this.node.current)
      .datum(this.props.data)
      .call(enterNode)
  }

  render() {
    const selectedNodeClass =
      this.props.name === this.props.tagSelected ? "selected-tag" : ""

    const isPathHighlighted =
      (this.props.group === 2 &&
        this.props.nodeSelected &&
        this.props.nodeSelected.parent === this.props.name) ||
      (this.props.group === 3 &&
        this.props.nodeSelected &&
        this.props.nodeSelected.macroCategory === this.props.macroCategory)

    return (
      <g
        className={"node " + selectedNodeClass}
        ref={this.node}
        onClick={() => {
          this.props.handleClickOnNode(
            this.props.name,
            this.props.macroCategory,
            this.props.group
          )
          //this.props.setStatisticsPanelIsOpen ();
          //this.props.toggleStatisticsPanelIsOpen()
          //this.props.toggleMapPanelIsOpen()
          this.props.toggleMacroMenuIsOpen()
        }}>
        {this.props.name === this.props.tagSelected ? (
          <circle className="selected-circle" />
        ) : (
          <circle />
        )}

        {this.props.name === this.props.tagSelected && (
          <circle className="overlay-circle" />
        )}

        {isPathHighlighted && <circle className="path-highlighted" />}

        <text>{this.props.data.name}</text>
      </g>
    )
  }
}

export default memo(Node)
