import React from "react"
import "./bar-chart-description.scss"

const BarChartDescription = props => {
  return (
    <div className="description-and-sources-box">
      <div className="description">
        <div className="description-title typo-body-2-chart-bold">
          Descrição
        </div>
        <div className="description-content typo-body-2-chart">
          {props.description}
        </div>
      </div>
      <div className="sources typo-body-2-bar-chart-smaller-bold">
        <div className="sources">{props.sources}</div>
        <div className="study">{props.study}</div>
      </div>
    </div>
  )
}

export default BarChartDescription
