import React from "react"
import * as d3 from "d3"

const enterLink = selection => {
  selection.attr("stroke-width", 1).attr("stroke", d => {
    let color = ""
    switch (d["macroCategory"]) {
      case "Média":
        color = "#F27F70"
        break
      case "Criações funcionais":
        color = "#FFC347"
        break
      case "Artes":
        color = "#03D7C6"
        break
      case "Património":
        color = "#E019EB"
        break
      case "Práticas criativas e culturais em geral":
        color = "#BDECFF"
        break
      default:
        color = "white"
        break
    }

    return color
  })
}

class Link extends React.Component {
  constructor(props) {
    super(props)
    this.link = React.createRef()
  }

  componentDidMount() {
    this.d3Link = d3
      .select(this.link.current)
      .datum(this.props.data)
      .call(enterLink)
  }

  componentDidUpdate() {
    this.d3Link = d3
      .select(this.link.current)
      .datum(this.props.data)
      .call(enterLink)
  }

  render() {
    return <line className="link" ref={this.link} />
  }
}

export default Link
