import React from "react"
import _ from "lodash"
import Graph from "./Graph"
import map from "lodash/fp/map"
import uniqBy from "lodash/fp/uniqBy"
import filter from "lodash/fp/filter"
import uniq from "lodash/fp/uniq"
import flow from "lodash/fp/flow"

class GraphContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      nodes: [],
      links: [],
      datasetSelected: 1,
      clickNoUpdate: false,
      macroCategoryActive: ["Indústrias Criativas"], //this macro category is always present
      containerHeight: null,
      nodeIdClicked: null,
    }

    this.icArr = []
    this.tagNodes = []
  }
  componentDidMount() {
    const data = this.props.data

    let obcreiContainerHeight = document.getElementsByClassName(
      "obcrei-container"
    )[0].clientHeight

    this.setState({
      data,
      containerHeight: obcreiContainerHeight,
    }) //build 1st dataset

    this.icArr = [
      {
        name: "Indústrias Criativas",
        parent: "",
        macroCategory: "Indústrias Criativas",
        group: 4,
        id: 0,
      },
    ]

    this.tagNodes = flow(
      map(d => {
        let group = 1
        return {
          name: d.node["tag1"],
          parent: d.node["industriasCriativasMicro"],
          group: group,
          macroCategory: d.node["industriasCriativasMacro"],
        }
      }),
      uniqBy("name")
    )(data)

    const nodesArrayUnion = _.union(this.icArr, this.tagNodes)

    const nodesArray = _.map(nodesArrayUnion, (d, i) => {
      return {
        ...d,
        id: i,
      }
    })

    const linksGraph = flow(
      filter(d => d["name"] !== "Indústrias Criativas"),
      map((d, i) => {
        return {
          source: d.id,
          target: 0, //root node when graph opens for the first time
          id: i,
          macroCategory: d["macroCategory"],
        }
      })
    )(nodesArray)

    this.setState({
      nodes: nodesArray,
      links: linksGraph,
    })

    window.addEventListener("clickMacroMenuItem", e =>
      //simulate click on node without node ID and with node group 5
      // which means a click on the macroCategory Menu

      this.handleClickOnNode(null, e.detail, 5)
    )
  }

  componentWillUnmount() {
    window.removeEventListener("clickMacroMenuItem", this.handleClickOnNode())
  }

  handleClickOnNode = (nodeId, macroCategory, nodeGroup) => {
    //logic for first time opening of macroCategory menu
    //it should open automatically only on first node click

    if (this.state.firstClickOnNodes === false) {
      this.props.toggleMacroMenuIsOpen()
      this.setState({
        firstClickOnNodes: true,
      })
    }

    var activeMacroCategories = [] //array for storing active macroCategories

    activeMacroCategories = this.props.macroCategoryActive

    //handler for clicks on graph element

    if (nodeGroup !== 5) {
      //add  macroCategory from macroCategories active on graph current state
      //if macroCategory of click don't exist in array of activeMacroCategories add it
      //then update state on root component

      if (_.indexOf(activeMacroCategories, macroCategory) === -1) {
        activeMacroCategories.push(macroCategory)

        this.props.setMacroCategoryActive(activeMacroCategories)
      }

      //if click is not on nodeGroups 1 (root) AND 4 (tags) AND 5 (menu) macroCategory of click
      //exists in macro categories that are active remove from active array
      //then update state on root component

      if (
        nodeGroup !== 1 &&
        nodeGroup !== 4 &&
        _.indexOf(activeMacroCategories, macroCategory) !== -1
      ) {
        _.remove(activeMacroCategories, item => {
          return item === macroCategory
        })

        this.props.setMacroCategoryActive(activeMacroCategories)
      }
    }

    //handler for clicks on menu

    if (nodeGroup === 5) {
      if (!_.includes(activeMacroCategories, macroCategory)) {
        activeMacroCategories.push(macroCategory)

        this.props.setMacroCategoryActive(activeMacroCategories)
      } else {
        _.remove(activeMacroCategories, item => {
          return item === macroCategory
        })

        this.props.setMacroCategoryActive(activeMacroCategories)
      }
    }

    //callback change tag selected on dashboard

    if (nodeGroup === 1 && nodeId !== null) {
      this.props.changeTagSelected(nodeId)
    }
    //change dataset loaded currently so force simulation configurations can change

    /*
    if (activeMacroCategories.length === 1) {
      this.setState({
        datasetSelected: 1,
      })
    } else {
      this.setState({
        datasetSelected: 2,
      })
    }
*/

    this.setState({
      datasetSelected: 2,
    })

    //build and change dataset loaded on graph

    let data = this.state.data

    // const microNodes = _.chain(data)
    //   .map(d => {
    //     let group = 2
    //     return {
    //       name: d.node["industriasCriativasMicro"],
    //       parent: d.node["industriasCriativasMacro"],
    //       group: group,
    //       macroCategory: d.node["industriasCriativasMacro"],
    //     }
    //   })
    //   .uniqBy("name")
    //   .value()

    const microNodes = flow(
      map(d => {
        let group = 2
        return {
          name: d.node["industriasCriativasMicro"],
          parent: d.node["industriasCriativasMacro"],
          group: group,
          macroCategory: d.node["industriasCriativasMacro"],
        }
      }),
      uniqBy("name")
    )(data)

    // const macroNodes = _.chain(data)
    //   .map(d => d.node["industriasCriativasMacro"])
    //   .uniq()
    //   .map(d => {
    //     let group = 3
    //     return {
    //       name: d,
    //       group: group,
    //       parent: "Indústrias Criativas",
    //       macroCategory: d,
    //     }
    //   })
    //   .value()

    const macroNodes = flow(
      map(d => d.node["industriasCriativasMacro"]),
      uniq,
      map(d => {
        let group = 3
        return {
          name: d,
          group: group,
          parent: "Indústrias Criativas",
          macroCategory: d,
        }
      })
    )(data)

    let nodesArrayUnion = _.union(
      this.icArr,
      macroNodes,
      microNodes,
      this.tagNodes
    )

    //create array with full graph for nodes with macroCategory active

    let nodesArrayActive = _.filter(nodesArrayUnion, d =>
      _.includes(activeMacroCategories, d.macroCategory)
    )

    //create array with tag nodes linked to root for nodes with macroCategory not active

    // let nodesArrayNotActive = _.chain(nodesArrayUnion)
    //   .filter(d => !_.includes(activeMacroCategories, d.macroCategory))
    //   .filter(d => d["group"] === 1)
    //   .map(d => {
    //     return {
    //       ...d,
    //       parent: "Indústrias Criativas",
    //     }
    //   })
    //   .value()

    let nodesArrayNotActive = flow(
      filter(d => !_.includes(activeMacroCategories, d.macroCategory)),
      filter(d => d["group"] === 1),
      map(d => {
        return {
          ...d,
          parent: "Indústrias Criativas",
        }
      })
    )(nodesArrayUnion)

    //join two arrays

    let nodesArrayPrepared = _.union(nodesArrayActive, nodesArrayNotActive)

    //feed nodes array for drawing

    // let nodesArray = _.chain(nodesArrayPrepared)
    //   .map((d, i) => {
    //     return {
    //       ...d,
    //       id: i,
    //     }
    //   })
    //   .value()

    let nodesArray = _.map(nodesArrayPrepared, (d, i) => {
      return {
        ...d,
        id: i,
      }
    })

    // let nodesArray = flow(
    //   map((d, i) => {
    //     return {
    //       ...d,
    //       id: i,
    //     }
    //   })
    // )(nodesArrayPrepared)

    //if tag nodes have a macro category not included their target is root

    // var linksGraph = _.chain(nodesArray)
    //   .filter(d => d["name"] !== "Indústrias Criativas")
    //   .map((d, i) => {
    //     var target = _.findIndex(nodesArray, o => {
    //       return o.name === d["parent"]
    //     })

    //     return {
    //       source: d.id,
    //       target: target,
    //       id: i,
    //       macroCategory: d["macroCategory"],
    //     }
    //   })
    //   .value()

    var linksGraph = flow(
      filter(d => d["name"] !== "Indústrias Criativas"),
      map((d, i) => {
        var target = _.findIndex(nodesArray, o => {
          return o.name === d["parent"]
        })

        return {
          source: d.id,
          target: target,
          id: i,
          macroCategory: d["macroCategory"],
        }
      })
    )(nodesArray)

    this.setState({
      lastMacroCategorySelected: macroCategory,
    })

    //change state -> draw graph

    this.setState({
      nodes: nodesArray,
      links: linksGraph,
      nodeIdClicked: nodeId,
    })
  }

  render() {
    return (
      <Graph
        links={this.state.links}
        nodes={this.state.nodes}
        containerHeight={this.state.containerHeight}
        handleClickOnNode={this.handleClickOnNode}
        nodeIdClicked={this.state.nodeIdClicked}
        datasetSelected={this.state.datasetSelected}
        clickNoUpdate={this.state.clickNoUpdate}
        tagSelected={this.props.tagSelected}
        toggleStatisticsPanelIsOpen={this.props.toggleStatisticsPanelIsOpen}
        toggleMapPanelIsOpen={this.props.toggleMapPanelIsOpen}
        toggleMacroMenuIsOpen={this.props.toggleMacroMenuIsOpen}
        setStatisticsPanelIsOpen={this.props.setStatisticsPanelIsOpen}
        mapPanelIsOpen={this.props.mapPanelIsOpen}
        macroCategoryActive={this.props.macroCategoryActive}
      />
    )
  }
}

export default GraphContainer

/*



*/
