import React, { Component } from "react"
import * as d3 from "d3"
import "./sunburst.scss"

const margin = { top: 18, right: 11, bottom: 18, left: 11 }
const width = 300
const height = 291
const radius = Math.min(width - margin.right, height - margin.top) / 2

export default class Sunburst extends Component {
  state = {
    data: [],
    dimensionSelected: null,
    creativeCycleSelected: null,
    uKey: 1,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { data, creativeCycleSelected, dimensionSelected } = nextProps
    if (!data) return {}

    return { data, creativeCycleSelected, dimensionSelected }
  }

  componentDidMount() {
    this.renderSuburst()
  }

  componentDidUpdate(prevProps, prevState) {
    this.renderSuburst(prevState)
  }

  renderSuburst(prevState) {
    //force refresh on sunburst if "limpar" is clicked or data changes

    if (
      prevState &&
      (prevState.dimensionSelected !== this.state.dimensionSelected ||
        prevState.creativeCycleSelected !== this.state.creativeCycleSelected ||
        prevState.data !== this.state.data) &&
      prevState.uKey === this.state.uKey
    )
      this.setState({
        uKey: this.state.uKey + 1,
      })

    const data = this.state.data

    const partition = d3.partition().size([2 * Math.PI, radius])

    const root = d3.hierarchy(data).sum(function(d) {
      return d.size
    })

    partition(root)
    var arc = d3
      .arc()
      .startAngle(function(d) {
        return d.x0
      })
      .endAngle(function(d) {
        return d.x1
      })
      .innerRadius(function(d) {
        return d.y0
      })
      .outerRadius(function(d) {
        return d.y1
      })

    d3.select(this.refs.g)
      .selectAll("g")
      .data(root.descendants())
      .enter()
      .append("g")
      .attr("class", d => {
        let classname = ""
        if (d.depth === 0) {
          classname =
            "sunburst-node typo-body-2-dark root-node depth-" + d.depth
        } else {
          let activity = ""
          if (!d.data.active) {
            activity = "inactive-node"
          } else {
            activity = "active-node"
          }

          classname = "sunburst-node depth-" + d.depth + " " + activity
        }
        return classname
      })
      .on("click", d => {
        if (d.depth === 1 && d.data.active) {
          this.props.setDimensionSelected(d.data.id)
        } else if (d.depth === 2 && d.data.active) {
          this.props.setCreativeCycleSelected(d.data.id)
        } else {
          return true
        }

        // force a refresh in the sunburst component
        this.setState({
          uKey: this.state.uKey + 1,
        })
      })
      .append("path")
      .attr("d", arc)
      .style("stroke", "#D8D8D8")
      .attr("fill", d => {
        if (
          d.data.id === this.state.creativeCycleSelected ||
          d.data.id === this.state.dimensionSelected
        ) {
          return "#f27f70"
        } else {
          return ""
        }
      })

    d3.select(this.refs.g)
      .selectAll("text")
      .remove()

    d3.select(this.refs.g)
      .selectAll(".sunburst-node")
      .append("text")
      .attr("transform", function(d) {
        return (
          "translate(" +
          arc.centroid(d) +
          ")rotate(" +
          computeTextRotation(d) +
          ")"
        )
      })
      .attr("dy", d => {
        if (d.depth === 0) return "-1em"
      }) // rotation align
      .text(d => d.data.id)
      .call(wrap, 80)

    function computeTextRotation(d) {
      var angle = ((d.x0 + d.x1) / Math.PI) * 90

      // Avoid upside-down labels
      return angle < 120 || angle > 270 ? angle : angle + 180 // labels as rims
      //return angle < 180 ? angle - 90 : angle + 90; // labels as spokes
    }

    function wrap(text, width) {
      text.each(function() {
        var text = d3.select(this),
          words = text
            .text()
            .split(/\s+/)
            .reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.2, // ems
          y = text.attr("y"),
          dy = parseFloat(text.attr("dy") || 0), // bit that breaks line
          tspan = text
            .text(null)
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", dy + "em")
        while ((word = words.pop())) {
          line.push(word)
          tspan.text(line.join(" "))
          if (tspan.node().getComputedTextLength() > width) {
            line.pop()
            tspan.text(line.join(" "))
            line = [word]
            tspan = text
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", `${lineHeight + dy}em`)
              .text(word)
          }
        }
      })
    }
  }

  render() {
    return (
      <div className="sunburst-container">
        <svg width={width} height={height} key={this.state.uKey}>
          <g transform={`translate(${width / 2}, ${height / 2})`} ref="g" />
        </svg>
      </div>
    )
  }
}
