import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import "./stepper.scss"
import Slider from "react-slick"
import "../../../helpers/styles/typography.scss"
/*
const StepperOverlay = props => (
  <StaticQuery
    query={graphql`
      query stepperInfo {
        dashboardInfoStepperJson {
          mainTitle
          titleFirst
          textContentFirst
          textContentFirstList
          titleSecond
          textContentSecond
          titleThird
          textContentThird
          textContentThirdList
          titleFourth
          textContentFourth
          textContentFourthList
          titleFifth
          textContentFifth
        }
      }
    `}
    render={data => {
      return <TextSlider data={data} {...props} />
    }}
  />
)
*/

class StepperOverlay extends Component {
  constructor(props) {
    super(props)

    this.nextSlide = this.nextSlide.bind(this)
    this.previousSlide = this.previousSlide.bind(this)
  }

  nextSlide() {
    this.slider.slickNext()
  }

  previousSlide() {
    this.slider.slickPrev()
  }

  render() {
    const stepperText = this.props.textData.childDashboardContentJson

    var settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      appendDots: dots => (
        <div
          style={{
            borderRadius: "10px",
            backgroundColor: "transparent",
          }}>
          <button
            type="button"
            className="arrow-button"
            onClick={() => this.previousSlide()}>
            <svg width="25px" height="25px" fill="#D6D4E3">
              <polygon points="15.2777778 1.22222222 5.5 11 15.2777778 20.7777778 16.6020545 19.453501 8.14855348 11 16.6020545 2.54649896" />
            </svg>
          </button>
          <ul
            style={{
              marginRight: "23px",
              marginLeft: "23px",
              marginTop: "0",
              marginBottom: "0",
              height: "50px",
              display: "flex",
              alignItems: "center",
              padding: 0,
            }}>
            {dots}
          </ul>

          <button
            type="button"
            className="arrow-button"
            onClick={() => this.nextSlide()}>
            <svg width="25px" height="25px" fill="#D6D4E3">
              <polygon points="6.82427674 1.22222222 16.6020545 11 6.82427674 20.7777778 5.5 19.453501 13.953501 11 5.5 2.54649896" />
            </svg>
          </button>
        </div>
      ),
    }

    return (
      <div className="stepper-overlay-wrapper">
        <div
          className="close-button-slider"
          onClick={this.props.toggleStepperOverlayActive}>
          <svg width="22px" height="22px">
            <circle fill="#1E1B2F" cx="11" cy="11" r="11" opacity="0.95" />
            <text
              fontFamily="Helvetica"
              fontSize="14"
              fontWeight="normal"
              letterSpacing="-0.21"
              fill="#FFFFFF">
              <tspan x="7" y="15">
                X
              </tspan>
            </text>
          </svg>
        </div>
        <div className="text-frame-header">
          <div
            className="text-header typo-body3-medium-white"
            ref={node => {
              if (node) {
                node.style.setProperty("font-family", "Montserrat", "important")
              }
            }}>
            {stepperText.mainTitle}
          </div>
        </div>
        <div className="text-frame">
          <Slider {...settings} ref={c => (this.slider = c)}>
            <div>
              <div className="slide-0">
                <h3
                  style={{ color: "#FFFFFF" }}
                  ref={node => {
                    if (node) {
                      node.style.setProperty(
                        "font-family",
                        "Montserrat",
                        "important"
                      )
                    }
                  }}>
                  {stepperText.titleFirst}
                </h3>
                <div className="text-content-slide">
                  <div
                    className="text-content-first-paragraph typo-body-regular-sobre-obcrei"
                    dangerouslySetInnerHTML={{
                      __html:
                        stepperText.textContentFirst.data.textContentFirst,
                    }}
                    style={{ color: "#FFFFFF" }}
                    ref={node => {
                      if (node) {
                        node.style.setProperty(
                          "font-family",
                          "Montserrat",
                          "important"
                        )
                      }
                    }}
                  />
                  <div className="text-content-div-container">
                    <div
                      className="text-content-list typo-body-regular-sobre-obcrei"
                      dangerouslySetInnerHTML={{
                        __html:
                          stepperText.textContentFirstList.data
                            .textContentFirstList,
                      }}
                      style={{ color: "#FFFFFF" }}
                      ref={node => {
                        if (node) {
                          node.style.setProperty(
                            "font-family",
                            "Montserrat",
                            "important"
                          )
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="slide-1">
                {" "}
                <h3
                  style={{ color: "#FFFFFF" }}
                  ref={node => {
                    if (node) {
                      node.style.setProperty(
                        "font-family",
                        "Montserrat",
                        "important"
                      )
                    }
                  }}>
                  {stepperText.titleSecond}
                </h3>
                <div
                  className="second-text-content-list typo-body-regular-sobre-obcrei"
                  dangerouslySetInnerHTML={{
                    __html:
                      stepperText.textContentSecond.data.textContentSecond,
                  }}
                  style={{ color: "#FFFFFF" }}
                  ref={node => {
                    if (node) {
                      node.style.setProperty(
                        "font-family",
                        "Montserrat",
                        "important"
                      )
                    }
                  }}
                />
              </div>
            </div>
            <div>
              <div className="slide-2">
                <h3
                  style={{ color: "#FFFFFF" }}
                  ref={node => {
                    if (node) {
                      node.style.setProperty(
                        "font-family",
                        "Montserrat",
                        "important"
                      )
                    }
                  }}>
                  {stepperText.titleThird}
                </h3>
                <div
                  className="text-content-first-paragraph typo-body-regular-sobre-obcrei"
                  dangerouslySetInnerHTML={{
                    __html: stepperText.textContentThird.data.textContentThird,
                  }}
                  style={{ color: "#FFFFFF" }}
                  ref={node => {
                    if (node) {
                      node.style.setProperty(
                        "font-family",
                        "Montserrat",
                        "important"
                      )
                    }
                  }}
                />
                <div className="text-content-div-container">
                  <div
                    className="text-content-list typo-body-regular-sobre-obcrei"
                    dangerouslySetInnerHTML={{
                      __html:
                        stepperText.textContentThirdList.data
                          .textContentThirdList,
                    }}
                    style={{ color: "#FFFFFF" }}
                    ref={node => {
                      if (node) {
                        node.style.setProperty(
                          "font-family",
                          "Montserrat",
                          "important"
                        )
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="slide-3">
                <h3
                  style={{ color: "#FFFFFF" }}
                  ref={node => {
                    if (node) {
                      node.style.setProperty(
                        "font-family",
                        "Montserrat",
                        "important"
                      )
                    }
                  }}>
                  {stepperText.titleFourth}
                </h3>

                <div
                  className="text-content-first-paragraph typo-body-regular-sobre-obcrei"
                  dangerouslySetInnerHTML={{
                    __html:
                      stepperText.textContentFourth.data.textContentFourth,
                  }}
                  style={{ color: "#FFFFFF" }}
                  ref={node => {
                    if (node) {
                      node.style.setProperty(
                        "font-family",
                        "Montserrat",
                        "important"
                      )
                    }
                  }}
                />
                <div className="text-content-div-container">
                  <div
                    className="text-content-list typo-body-regular-sobre-obcrei"
                    dangerouslySetInnerHTML={{
                      __html:
                        stepperText.textContentFourthList.data
                          .textContentFourthList,
                    }}
                    style={{ color: "#FFFFFF" }}
                    ref={node => {
                      if (node) {
                        node.style.setProperty(
                          "font-family",
                          "Montserrat",
                          "important"
                        )
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="slide-4">
                <h3
                  style={{ color: "#FFFFFF" }}
                  ref={node => {
                    if (node) {
                      node.style.setProperty(
                        "font-family",
                        "Montserrat",
                        "important"
                      )
                    }
                  }}>
                  {stepperText.titleFifth}
                </h3>

                <div
                  className="second-text-content-list typo-body-regular-sobre-obcrei"
                  dangerouslySetInnerHTML={{
                    __html: stepperText.textContentFifth.data.textContentFifth,
                  }}
                  style={{ color: "#FFFFFF" }}
                  ref={node => {
                    if (node) {
                      node.style.setProperty(
                        "font-family",
                        "Montserrat",
                        "important"
                      )
                    }
                  }}
                />
              </div>
            </div>
          </Slider>
        </div>
      </div>
    )
  }
}

export default StepperOverlay

/*


<Slider {...settings} ref={c => (this.slider = c)}>
            <div style={{color: 'white', backgroundColor: 'red'}}>
              <p>{stepperText.titleFirst}</p>
            </div>
            <div style={{color: 'white', backgroundColor: 'blue'}}>
              <p>{stepperText.titleSecond}</p>
            </div>
            <div style={{color: 'white', backgroundColor: 'yellow'}}>
              <p>{stepperText.titleThird}</p>
            </div>
          </Slider>


*/

/*
    var settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      appendDots: dots => (
        <div
          style={{
            borderRadius: '10px',
            backgroundColor: 'transparent',
          }}
        >
          <button
            type="button"
            className="arrow-button"
            onClick={() => this.previousSlide ()}
          >
            <svg width="50px" height="50px" fill="white">
              <path
                transform="translate(1.000000, 1.000000)"
                d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,46 C36.1502645,46 46,36.1502645 46,24 C46,11.8497355 36.1502645,2 24,2 C11.8497355,2 2,11.8497355 2,24 C2,36.1502645 11.8497355,46 24,46 Z"
                id="path-1"
              />
              <polygon
                transform="translate(13.000000, 14.000000)"
                id="path-3"
                points="15.2777778 1.22222222 5.5 11 15.2777778 20.7777778 16.6020545 19.453501 8.14855348 11 16.6020545 2.54649896"
              />
            </svg>
          </button>
          <ul
            style={{
              marginRight: '23px',
              marginLeft: '23px',
              marginTop: '0',
              marginBottom: '0',
              height: '50px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {dots}
          </ul>

          <button
            type="button"
            className="arrow-button"
            onClick={() => this.nextSlide ()}
          >
            <svg width="50px" height="50px" fill="white">
              <path
                transform="translate(1.000000, 1.000000)"
                d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,46 C36.1502645,46 46,36.1502645 46,24 C46,11.8497355 36.1502645,2 24,2 C11.8497355,2 2,11.8497355 2,24 C2,36.1502645 11.8497355,46 24,46 Z"
                id="path-1"
              />
              <polygon
                transform="translate(15.000000, 14.000000)"
                id="path-3"
                points="6.82427674 1.22222222 16.6020545 11 6.82427674 20.7777778 5.5 19.453501 13.953501 11 5.5 2.54649896"
              />
            </svg>
          </button>
        </div>
      ),
    };
*/
