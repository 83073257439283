import React, { Component } from "react"
import * as d3 from "d3"
import _, { last } from "lodash"
import "./dot-plot.scss"
import "../../../../helpers/styles/typography.scss"
import "../../../../helpers/styles/general.scss"
import map from "lodash/fp/map"
import flatten from "lodash/fp/flatten"
import filter from "lodash/fp/filter"
import flow from "lodash/fp/flow"
import {
  datavisColors,
  datavisDefaultColor,
} from "../../../../helpers/obcrei-helpers"
import { color } from "d3"

const margin = { top: 7, right: 69, bottom: 62, left: 100 } // left and bottom - axes to borders; top - top div; right legend to border -15
const width = 580
const height = 275

function wrap(text, width) {
  text.each(function() {
    var text = d3.select(this),
      words = text
        .text()
        .split(/\s+/)
        .reverse(),
      word,
      line = [],
      //lineNumber = 0,
      lineHeight = 1.1, // ems
      x = text.attr("x"),
      y = text.attr("y"),
      dy = 0, //parseFloat(text.attr('dy')),
      tspan = text
        .text(null)
        .append("tspan")
        .attr("x", x)
        .attr("y", y)
        .attr("dy", dy + "em")

    if (words.length >= 10) {
      words = words.splice(2, words.length)
      words.unshift("...")
    }

    while ((word = words.pop())) {
      line.push(word)
      tspan.text(line.join(" "))
      if (tspan.node().getComputedTextLength() > width) {
        line.pop()
        tspan.text(line.join(" "))
        line = [word]
        tspan = text
          .append("tspan")
          .attr("x", x)
          .attr("y", y)
          .attr("dy", lineHeight + dy + "em")
          .text(word)
      }
    }
  })
}

export default class componentName extends Component {
  state = { lines: [], tooltip: false, legendHovered: null }

  xAxis = d3
    .axisBottom()
    //.ticks(5, ".0r")
    .ticks(5, ".1s")
    .tickSize(-(height - margin.top - margin.bottom))
    .tickSizeOuter(0)

  yAxis = d3
    .axisLeft()
    .tickSizeOuter(0)
    .tickSize(0)

  static getDerivedStateFromProps(nextProps, prevState) {
    const { data } = nextProps
    if (!data) return {}

    //debug adding years
    // data[0][2011] = 1000
    // data[0][2020] = 2000

    //1. map year value to x-position

    const arrAllValues = flow(
      map(d => Object.values(d)),
      flatten,
      filter(d => typeof d === "number")
    )(data)

    const scale = d3.scaleLinear().domain([0, d3.max(arrAllValues)])

    const xScale = scale.range([margin.left, width - margin.right]) // padding to right of svg -- width of a dot

    //added for tinkering with axis limits - analyse if it is needed
    const xScaleAxis = scale.range([margin.left, width - margin.right])

    //2. map indicator name to y-position

    const yScale = d3
      .scaleBand()
      .range([height - margin.bottom, margin.top])
      .domain(data.map(d => d["indicador"]))
      .padding(1)

    //3. build color scale for dots - DEPRECATED

    //var colorScale = d3.scaleOrdinal().domain(keys)
    //.range(colors)

    //4. build lines and contained value dots to map in render

    const maxAllValues = xScale(d3.max(arrAllValues)) // max of all values mapped to x position

    //curto circuito

    var colors = []

    const lines = _.map(data, d => {
      let objectValues = Object.values(d)
      let yearValues = _.filter(objectValues, d => typeof d === "number")

      let objectKeys = Object.keys(d)
      let years = _.filter(objectKeys, d => d !== "indicador")

      let limitYears = 5
      let yearsCopy = [...years]

      let last4Years = yearsCopy.slice(-limitYears + 1)

      years.map((d, index) => {
        let colorObj = []

        if (years.length <= 4) {
          colorObj = { year: d, color: datavisColors[index] }
        } else {
          if (last4Years.includes(d)) {
            colorObj = { year: d, color: datavisColors[index - 1] }
          } else {
            colorObj = { year: d, color: datavisDefaultColor }
          }
        }

        colors.push(colorObj)
      })

      let valArr = []

      _.map(yearValues, u => {
        valArr.push(xScale(u))
      })

      const yearScaledValues = []
      years.map((e, i) => {
        return yearScaledValues.push(
          { [e]: { scaled: valArr[i], real: yearValues[i] } } //[e] + ":" + arr2[i]
        )
      })

      return {
        x1: maxAllValues,
        x2: xScale(0),
        y: yScale(d["indicador"]),
        yearValArr: yearScaledValues,
        indicator: d.indicador,
      }
    })

    colors = _.uniqBy(colors, function(e) {
      return e.year
    })

    //5. build legends for graph

    const legends = _.map(colors, (d, i) => {
      let fillColor = d.color
      return {
        cy: i * 17,
        fill: fillColor,
        text: d.year,
      }
    })

    return { lines, xScale, yScale, xScaleAxis, legends, colors }
  }

  componentDidMount() {
    this.xAxis.scale(this.state.xScaleAxis)
    d3.select(this.refs.xAxis)
      .call(this.xAxis)
      .selectAll(".tick text")
      .attr("class", "typo-body-2-chart-smaller-bold")

    this.yAxis.scale(this.state.yScale)
    d3.select(this.refs.yAxis)
      .call(this.yAxis)
      .selectAll(".tick text")
      .call(wrap, 85)
      .attr("class", "typo-body-2-chart")
      .on("click", d => {
        this.props.setIndicatorSelected(d)
        this.props.toggleIndicatorDetailActive()
      })
      .classed("selected-axis-text", d => d === this.props.indicatorSelected)

    d3.select(this.refs.dotsGroup)
      .selectAll(".horizontal-data-group")
      .data(this.state.lines)
      .selectAll("circle")
      .data(d => d.yearValArr)
      .attr("cx", this.state.xScale(0))
      .transition()
      .duration(2500)
      //.delay((d, i) => i * -50)
      .attr("cx", d => {
        const values = Object.values(d)
        const scaledValue = values[0].scaled
        return scaledValue
      })
  }

  componentDidUpdate(prevProps, prevState) {
    this.xAxis.scale(this.state.xScaleAxis)
    d3.select(this.refs.xAxis)
      .call(this.xAxis)
      .selectAll(".tick text")
      .attr("class", "typo-body-2-chart-smaller-bold")

    this.yAxis.scale(this.state.yScale)
    d3.select(this.refs.yAxis)
      .call(this.yAxis)
      .selectAll(".tick text")
      .call(wrap, 90)
      .attr("class", "typo-body-2-chart")
      .on("click", d => {
        this.props.setIndicatorSelected(d)
        this.props.toggleIndicatorDetailActive()
      })
      .classed("selected-axis-text", d => d === this.props.indicatorSelected)

    if (!_.isEqual(this.props.data, prevProps.data)) {
      d3.select(this.refs.dotsGroup)
        .selectAll(".horizontal-data-group")
        .data(this.state.lines)
        .selectAll("circle")
        .data(d => d.yearValArr)
        .attr("cx", this.state.xScale(0))
        .transition()
        .duration(2500)
        //.delay((d, i) => i * -50)
        .attr("cx", d => {
          const values = Object.values(d)
          const scaledValue = values[0].scaled
          return scaledValue
        })
    }
  }

  handleMouseEvent(tooltip, indicator, year) {
    var tooltipWithStyle = false

    if (tooltip) {
      const objValue = Object.values(tooltip)

      const realValue = objValue[0].real
      const scaledValue = objValue[0].scaled

      let fillColor

      this.state.colors.map(d => {
        if (Number(d.year) === year) {
          fillColor = d.color
        }
      })

      tooltipWithStyle = {
        ...tooltip,
        realValue: realValue,
        scaleValue: scaledValue,
        y: this.state.yScale(indicator),
        style: { position: "absolute", zIndex: "10" },
        fill: fillColor,
        year: year,
      }

      this.setState({ tooltip: tooltipWithStyle })
    } else {
      this.setState({ tooltip: false })
    }
  }

  handleMouseEventLegend(movement, elementHovered) {
    if (movement === "mouseEnter") {
      this.setState({
        legendHovered: elementHovered,
      })
    } else if (movement === "mouseLeave") {
      this.setState({ legendHovered: null })
    }
  }

  render() {
    const legendsHeight = this.state.legends[this.state.legends.length - 1].cy
    const dotRadius = 7

    return (
      <svg width={width} height={height}>
        <g
          className="x-axis"
          ref="xAxis"
          transform={`translate(0, ${height - margin.bottom})`}
        />
        <g
          className="y-axis"
          ref="yAxis"
          transform={`translate(${margin.left}, 0)`}
        />
        <g
          className="dot-plot-legends"
          transform={`translate(${width - 45},  ${height -
            margin.bottom -
            legendsHeight -
            dotRadius})`}>
          {_.map(this.state.legends, (d, i) => (
            <g
              className={
                this.state.legendHovered === null ||
                this.state.legendHovered === i
                  ? "legend"
                  : "legend legend-not-hovered"
              }
              key={i}
              onMouseEnter={() => this.handleMouseEventLegend("mouseEnter", i)}
              onMouseLeave={() =>
                this.handleMouseEventLegend("mouseLeave", null)
              }>
              <circle
                className="dots-legends"
                cx={0}
                r={dotRadius}
                cy={d.cy}
                fill={d.fill}
              />
              <text
                x={dotRadius + 7}
                y={d.cy}
                className="typo-body-2-chart-legends-bold"
                fill="#d6d4e3"
                fontSize={9}>
                {d.text}
              </text>
            </g>
          ))}
        </g>
        <g ref="dotsGroup">
          {_.map(this.state.lines, (d, i) => {
            return (
              <g key={i} className="horizontal-data-group">
                <line
                  className="horizontal-lines"
                  stroke={
                    d.indicator === this.props.indicatorSelected
                      ? "rgba(218,218,235,0.90)"
                      : "rgba(218,218,235,0.30)"
                  }
                  x1={d.x1}
                  x2={d.x2}
                  y1={d.y}
                  y2={d.y}
                />
                <g className="dots-group">
                  {_.map(d.yearValArr, (u, index) => {
                    const year = parseFloat(Object.keys(u)[0])

                    let color = ""

                    this.state.colors.map(d => {
                      if (Number(d.year) === year) {
                        color = d.color
                      }
                    })

                    return (
                      <g key={index}>
                        <circle
                          onMouseEnter={() =>
                            this.handleMouseEvent(u, d.indicator, year)
                          }
                          onMouseLeave={() => this.handleMouseEvent(null)}
                          onClick={() => {
                            this.props.setYearSelected(year)
                            this.props.setIndicatorSelected(d.indicator)
                          }}
                          className={
                            this.state.legendHovered === null ||
                            (this.state.legendHovered !== null &&
                              this.state.legendHovered === index)
                              ? "dot"
                              : "dot element-not-hovered-legend"
                          }
                          key={index}
                          //cx={scaledValue}
                          r={7}
                          cy={d.y}
                          fill={color}
                          stroke={color}
                          strokeWidth={
                            year === this.props.yearSelected &&
                            d.indicator === this.props.indicatorSelected
                              ? "8"
                              : ""
                          }
                          strokeOpacity={
                            year === this.props.yearSelected &&
                            d.indicator === this.props.indicatorSelected
                              ? "0.35"
                              : ""
                          }
                        />
                        {this.state.tooltip && (
                          <g>
                            <text
                              className="typo-body-2-bold"
                              x={this.state.tooltip.scaleValue - 30}
                              y={this.state.tooltip.y - 32}
                              fill={this.state.tooltip.fill}
                              textAnchor={"left"}>
                              Ano: {this.state.tooltip.year}
                            </text>
                            <text
                              className="typo-body-2-bold"
                              x={this.state.tooltip.scaleValue - 30}
                              y={this.state.tooltip.y - 17}
                              fill={this.state.tooltip.fill}
                              textAnchor={"left"}>
                              Valor: {this.state.tooltip.realValue}
                            </text>
                          </g>
                        )}
                      </g>
                    )
                  })}
                </g>
              </g>
            )
          })}
        </g>
      </svg>
    )
  }
}
