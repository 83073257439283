import React, { Component } from "react"

import Autocomplete from "react-autocomplete"
import _ from "lodash"
import "../../../../helpers/styles/typography.scss"
import "./search.scss"
import map from "lodash/fp/map"
import uniqBy from "lodash/fp/uniqBy"
import flow from "lodash/fp/flow"

const SearchFormAutoComplete = props => {
  let value = props.value

  // let tagsArray = _.chain(props.data)
  //   .map(d => _.pick(d.node, ["tag1"]))
  //   .uniqBy("tag1")
  //   .map(d => {
  //     return {
  //       label: d["tag1"],
  //       category: "tag",
  //     }
  //   })
  //   .value()

  let tagsArray = flow(
    map(d => _.pick(d.node, ["tag1"])),
    uniqBy("tag1"),
    map(d => {
      return {
        label: d["tag1"],
        category: "tag",
      }
    })
  )(props.data)

  tagsArray = _.concat({ header: "Tags", label: "tags" }, tagsArray)

  // let indicadoresArray = _.chain(props.data)
  //   .map(d => _.pick(d.node, ["indicador", "tag1"]))
  //   .map(d => {
  //     return {
  //       label: d["indicador"],
  //       category: "indicadores",
  //       tag: d["tag1"],
  //     }
  //   })
  //   .value()

  let indicadoresArray = flow(
    map(d => _.pick(d.node, ["indicador", "tag1"])),
    map(d => {
      return {
        label: d["indicador"],
        category: "indicadores",
        tag: d["tag1"],
      }
    })
  )(props.data)

  indicadoresArray = _.concat(
    { header: "Indicadores", label: "indicadores" },
    _.uniqBy(indicadoresArray, "label")
  )

  const items = _.concat(tagsArray, indicadoresArray)

  //const tagsFlatNoDups = _.uniq (tagsFlatList);

  var selectHighlihted = function(item) {
    if (value === "") return item

    var newText = String(item).replace(
      new RegExp(value, "gi"),
      "<span class='ui-state-highlight'}>$&</span>"
    )

    return newText
  }

  //menu styles

  var menuStyle = {
    boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.5)",
    background: "#393E46",
    paddingTop: "2px",
    paddingBottom: "5px",
    fontSize: "90%",
    overflow: "auto",
    position: "absolute",
    top: "24px",
    left: 0,
    width: "100%",
    maxHeight: "400px",
  }

  return (
    <div className="search-box-form">
      <Autocomplete
        items={items}
        wrapperProps={{ className: "wrapper-autocomplete" }}
        shouldItemRender={(item, value) => {
          return (
            item.header ||
            item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
          )
        }}
        getItemValue={item => item.label}
        renderMenu={(
          items,
          value,
          style
          //TODO solve key
        ) => (
          <div
            className="menu"
            style={{ ...style, ...menuStyle }}
            children={items}
          />
        )}
        renderItem={(item, highlighted) =>
          item.header ? (
            <div
              className="item item-header typo-captions-bold"
              key={item.header}>
              {item.header}
            </div>
          ) : (
            <div
              className="item item-label typo-captions-regular"
              style={{
                //TODO change to scss file
                background: highlighted
                  ? "rgba(79, 106, 203,0.1)"
                  : "transparent",
                paddingRight: "5px",
                paddingLeft: "5px",
              }}>
              <div
                //change here highlight where the cursor is
                dangerouslySetInnerHTML={{
                  __html: selectHighlihted(item.label),
                }}
              />
            </div>
          )
        }
        renderInput={args => {
          const strArr = [...args.value]
          return (
            <div className="search-input-wrapper typo-captions-bold">
              <input {...args} className="typo-captions-bold" />
              {strArr.length === 0 && (
                <label
                  htmlFor="input"
                  className="control-label typo-captions-regular-italic">
                  Procurar...
                </label>
              )}
              <i className="bar" />
            </div>
          )
        }}
        value={value}
        onChange={e => props.searchValueSetter(e.target.value)}
        onSelect={(value, item) => {
          if (item.category === "tag") {
            props.changeTagSelected(value)
            props.setStatisticsPanelIsOpen()
            props.toggleSearchIsActive()
          } else {
            props.setIndicatorSelected(value)
            props.changeTagSelected(item.tag)
            props.toggleSearchIsActive()
          }
        }}
        isItemSelectable={item => !item.header}
      />
    </div>
  )
}

export default SearchFormAutoComplete

/*

<label htmlFor="input" className="control-label">
              Search
            </label>


*/
