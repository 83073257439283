import React, { Component } from "react"
import * as d3 from "d3"
import _ from "lodash"
import "../../../../helpers/styles/typography.scss"
import "../../../../helpers/styles/general.scss"
import "./bar-chart.scss"

const margin = { top: 20, right: 14, bottom: 16, left: 14 } // left and bottom - axes to borders; top - top div; right legend to border -15
const width = 237
const height = 240
const colors = ["#00A5E0", "#FFEF07", "#F0387E", "#137547"] //color for dots
let keys = []

/*
props example

data: Array(3)
0: {year: 2015, value: 13660668}
1: {year: 2016, value: 15532379}
2: {year: 2017, value: 17174986}
setYearSelected: ƒ (yearSelected)
yearSelected: 2016
*/

export default class BarChart extends Component {
  state = {
    bars: [],
    labels: [],
    yearHovered: null,
    data: [],
    yearsToShow: [],
  }

  xAxis = d3
    .axisBottom()
    .ticks(3, ".0r")
    .tickSizeOuter(0)

  static getDerivedStateFromProps(nextProps, prevState) {
    const { data, yearsToDisplay, yearSelected, yMax } = nextProps
    if (!data) return {}

    const yearsToShow = []

    yearsToDisplay.map(d => yearsToShow.push(d.value))

    keys = _.sortBy(yearsToShow)

    const filteredData = data.filter(d => _.includes(yearsToShow, d.year))

    //1. map year value to x-position

    const xScale = d3
      .scaleBand()
      .domain(filteredData.map(d => d.year))
      .range([margin.left, width - margin.right])
      .paddingInner(0.25)
      .paddingOuter(0.6)

    //2. map year values to y-position

    const yScale = d3
      .scaleLinear()
      .domain([0, yMax])
      .range([height - margin.bottom, margin.top])

    //3. build color scale for bars - deprecated

    // var colorScale = d3
    //   .scaleOrdinal()
    //   .domain(keys)
    //   .range(colors)

    //4. build bars

    const bars = _.map(filteredData, d => {
      const y1 = yScale(d.value)
      const y2 = yScale(0)

      return {
        x: xScale(d.year),
        y: y1,
        height: y2 - y1,
        width: xScale.bandwidth(),
        fill: d.color,
        year: d.year,
      }
    })

    //5.build labels

    const labels = _.map(filteredData, d => {
      return {
        x: xScale(d.year),
        y: yScale(d.value) - 10,
        year: d.year,
        text: d.value,
        fill: d.color,
      }
    })

    return { bars, xScale, yScale, labels, yearSelected, data: filteredData }
  }

  componentDidMount() {
    this.xAxis.scale(this.state.xScale)
    d3.select(this.refs.xAxis).call(this.xAxis)

    d3.select(this.refs.label)
      .selectAll("text")
      .data(this.state.bars)
      .attr("x", d => {
        return this.state.xScale(d.year) + this.state.xScale.bandwidth() / 2
      })
      .attr("text-anchor", "middle")

    d3.select(this.refs.barsGroup)
      .selectAll("rect")
      .data(this.state.bars)
      .attr("height", d => height - this.state.yScale(0))
      .attr("y", d => this.state.yScale(0) - margin.bottom)
      .transition()
      .duration(1500)
      .attr("y", d => d.y)
      .attr("height", d => d.height)
  }

  componentDidUpdate(prevProps, prevState) {
    this.xAxis.scale(this.state.xScale)
    d3.select(this.refs.xAxis).call(this.xAxis)

    d3.select(this.refs.label)
      .selectAll("text")
      .data(this.state.bars)
      .attr("x", d => {
        return this.state.xScale(d.year) + this.state.xScale.bandwidth() / 2
      })
      .attr("text-anchor", "middle")

    if (!_.isEqual(this.props.data, prevProps.data)) {
      // this comparison evaluates wrongly to true on click
      d3.select(this.refs.barsGroup)
        .selectAll("rect")
        .data(this.state.bars)
        .attr("height", d => height - this.state.yScale(0))
        .attr("y", d => this.state.yScale(0) - margin.bottom)
        .transition()
        .duration(1500)
        .attr("y", d => d.y)
        .attr("height", d => d.height)
    } else {
      d3.select(this.refs.barsGroup)
        .selectAll("rect")
        .data(this.state.bars)
        .attr("y", d => d.y)
        .attr("height", d => d.height)
    }
  }

  render() {
    return (
      <svg width={width} height={height}>
        <g className="bars" ref="barsGroup">
          {this.state.bars.map((d, i) => (
            <g
              key={i}
              className="bar"
              onClick={() => this.props.setYearSelected(d.year)}
              onMouseEnter={() => this.setState({ yearHovered: d.year })}
              onMouseLeave={() => this.setState({ yearHovered: null })}>
              <rect x={d.x} width={d.width} fill={d.fill} />
            </g>
          ))}
        </g>
        <g>
          {this.state.bars.map((d, i) => (
            <g
              key={i}
              className="bar"
              onClick={() => this.props.setYearSelected(d.year)}
              onMouseEnter={() => this.setState({ yearHovered: d.year })}
              onMouseLeave={() => this.setState({ yearHovered: null })}>
              {(this.state.yearSelected === d.year ||
                this.state.yearHovered === d.year) && (
                <g>
                  <rect
                    x={d.x - 7}
                    y={d.y - 7}
                    width={d.width + 14}
                    height={d.height + 7}
                    fill={d.fill}
                    fillOpacity="0.35"
                  />
                </g>
              )}
            </g>
          ))}
          }
        </g>
        <g className="labels" ref="label">
          {this.state.labels.map((d, index) => (
            <text key={index} y={d.y} fill={d.fill}>
              {d.text}
            </text>
          ))}
        </g>
        <g>
          <g
            ref="xAxis"
            className="xAxis-bar typo-body-2-chart-legends-bold"
            transform={`translate(0, ${height - margin.bottom})`}
          />
        </g>
      </svg>
    )
  }
}

/*

{(this.state.yearSelected === d.year ||
                this.state.yearHovered === d.year) && (
                <g>
                  <rect 
                  x={d.x - 7}
                  y={d.y - 7}
                  width={d.width + 14}
                  height={d.height + 7}
                  fill={d.fill}
                  fillOpacity="0.35"
                />
                </g>
              )}


*/
