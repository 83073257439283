import React from "react"
import { StaticQuery, graphql } from "gatsby"
import GraphContainer from "./Graph/GraphContainer"
import MacroMenu from "./macroMenu/MacroMenu"
import CircularMenu from "./circularMenu/CircularMenu"
import MapContainer from "./map/MapContainer"
import SimpleTextContentOverlay from "./informationContent/SimpleTextContentOverlay"
import StepperOverlay from "./informationContent/Stepper"
import "../../helpers/styles/general.scss"
import "../../helpers/styles/typography.scss"
import StatisticsPanel from "./statisticsPanel/StatisticsPanel"
import "./root-dashboard-component.scss"
import _ from "lodash"
import MouseTooltip from "react-sticky-mouse-tooltip"
import map from "lodash/fp/map"
import uniq from "lodash/fp/uniq"
import flow from "lodash/fp/flow"

import { BrowserView, MobileOnlyView } from "react-device-detect"

// ORIGINAL CODE
//const RootDashboardComponent = props => (
//   <StaticQuery
//     query={graphql`
//       query excelData {
//         allBaseDadosIndicadoresV23XlsxDb {
//           edges {
//             node {
//               industriasCriativasMacro
//               industriasCriativasMicro
//               tag
//               tag1
//               dimensao
//               cicloCriativo
//               escalaMicro
//               temporalidadeAno
//               temporalidadeSemestre
//               indicador
//               valor
//               fonte
//               estudo
//               tagGroup
//               descricao
//             }
//           }
//         }
//       }
//     `}
//     render={data => <DataVisRoot data={data} {...props} />}
//   />
// )

const exchangeData = (row, data) => {
  const defaultData = {
    node: {
      industriasCriativasMacro: row.industriasCriativasMacro,
      industriasCriativasMicro: row.industriasCriativasMicro,
      tag: row.tag,
      tag1: row.tag1,
      dimensao: row.dimensao,
      cicloCriativo: row.cicloCriativo,
      escalaMicro: undefined,
      temporalidadeAno: undefined,
      temporalidadeSemestre: "Anual",
      indicador: row.nome,
      valor: undefined,
      fonte: "", // Todo: add fonte
      estudo: row.fonte,
      tagGroup: +row.tagGroup,
      descricao: `${row.nome} - ${row.unidadeMedida}`,
    },
  }

  row.indicador_dados.forEach(indicador_dado => {
    const newData = _.cloneDeep(defaultData)
    newData.node.valor = indicador_dado.valor
    newData.node.temporalidadeAno = indicador_dado.timestamp
    newData.node.escalaMicro = indicador_dado.divisao_administrativa.nome

    data.push(newData)
  })
  return data
}

const RootDashboardComponent = props => (
  <StaticQuery
    query={graphql`
      query obcreiDashbordData {
        allBaseDadosIndicadoresV23XlsxDb: allStrapiIndicador {
          edges {
            node {
              industriasCriativasMacro
              industriasCriativasMicro
              tag
              tag1
              dimensao
              cicloCriativo
              indicador_dados {
                divisao_administrativa {
                  nome
                }
                timestamp
                valor
              }
              nome
              fonte
              tagGroup
              unidadeMedida
            }
          }
        }
      }
    `}
    render={data => <DataVisRoot data={data} {...props} />}
  />
)

class DataVisRoot extends React.Component {
  state = {
    tagSelected: "",
    macroCategoryActive: ["Indústrias Criativas"], //this macro category is always present
    indicatorDetailActive: false,
    macroMenuIsOpen: false,
    statisticsPanelIsOpen: false,
    //yearSelected: "2016", // NOTE: default for testing
    //regionSelected: ["Portugal"],
    yearsAvailableNUTS2: [],
    regionSelected: ["Portugal"],
    indicatorSelected: null,
    indicatorGroupSelected: null, //testing purposes
    circularMenuIsOpen: false,
    creativeCycleSelected: null,
    dimensionSelected: null,
    mapPanelIsOpen: false,
    searchIsActive: false,
    introOverlayActive: true,
    stepperOverlayActive: false,
    isMouseTooltipVisible: false,
    originOfTooltipCall: "",
  }

  setYearsAvailabelNUTS2 = years => {
    this.setState({
      yearsAvailableNUTS2: years,
    })
  }

  setMouseTooltipVisible(originOfTooltipCall) {
    this.setState({
      isMouseTooltipVisible: true,
      originOfTooltipCall,
    })
  }

  setMouseTooltipNotVisible() {
    this.setState({
      isMouseTooltipVisible: false,
    })
  }

  togglerMouseTooltipVisible(originOfTooltipCall) {
    this.setState({
      isMouseTooltipVisible: !this.state.isMouseTooltipVisible,
      originOfTooltipCall,
    })
  }

  hoverMenuButtons() {
    if (!this.state.tagSelected)
      this.setState({
        isMouseTooltipVisible: !this.state.isMouseTooltipVisible,
      })
  }

  setYearSelected = yearSelected => {
    yearSelected = parseFloat(yearSelected)
    this.setState({
      yearSelected,
    })
  }

  setIntroLayerOverlayFalse = () => {
    this.setState({
      introOverlayActive: false,
    })
  }

  setIndicatorSelected = indicatorSelected => {
    this.setState({
      indicatorSelected,
    })
  }

  setRegionSelected = regionSelected => {
    this.setState({
      regionSelected,
    })
  }

  setCreativeCycleSelected = creativeCycleSelected => {
    return creativeCycleSelected === this.state.creativeCycleSelected
      ? this.setState({ creativeCycleSelected: null })
      : this.setState({ creativeCycleSelected })
  }

  setDimensionSelected = dimensionSelected => {
    return dimensionSelected === this.state.dimensionSelected
      ? this.setState({ dimensionSelected: null })
      : this.setState({ dimensionSelected })
  }

  setIndicatorGroupSelected = indicatorGroupSelected => {
    this.setState({
      indicatorGroupSelected: indicatorGroupSelected,
      indicatorSelected: null,
      //yearSelected: null,
      //circularMenuIsOpen: false,
    })
  }

  changeTagSelected = tagSelected => {
    this.setState({
      tagSelected,
      mapPanelIsOpen: true, //testing
      statisticsPanelIsOpen: true,
      indicatorDetailActive: false,
    })
  }

  setStatisticsPanelIsOpen = () => {
    this.setState({
      statisticsPanelIsOpen: true,
    })
  }

  setMacroCategoryActive = arr => {
    this.setState({
      macroCategoryActive: arr,
    })
  }

  toggleMacroMenuIsOpen = () => {
    this.setState({
      macroMenuIsOpen: !this.state.macroMenuIsOpen,
    })
  }

  toggleStepperOverlayActive = () => {
    this.setState({
      stepperOverlayActive: !this.state.stepperOverlayActive,
    })
  }

  toggleSearchIsActive = () => {
    this.setState({
      searchIsActive: !this.state.searchIsActive,
    })
  }

  toggleMapPanelIsOpen = () => {
    //if (!this.state.tagSelected) return

    this.setState({
      mapPanelIsOpen: !this.state.mapPanelIsOpen,
    })
  }
  toggleStatisticsPanelIsOpen = () => {
    this.setState({
      statisticsPanelIsOpen: !this.state.statisticsPanelIsOpen,
    })
  }

  toggleCircularMenuIsOpen = () => {
    this.setState({
      circularMenuIsOpen: !this.state.circularMenuIsOpen,
    })
  }

  toggleIndicatorDetailActive = () => {
    this.setState({
      indicatorDetailActive: !this.state.indicatorDetailActive,
      //circularMenuIsOpen: false,
    })
  }

  changeMacroCategoryActive = category => {
    let currentMacroActive = this.state.macroCategoryActive

    if (_.indexOf(currentMacroActive, category) === -1) {
      currentMacroActive.push(category)
    } else {
      _.filter(currentMacroActive, d => d === category)
    }

    this.setState({
      macroCategoryActive: currentMacroActive,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.regionSelected !== this.state.regionSelected) {
      if (this.state.regionSelected.includes("Portugal")) {
        this.setState({
          indicatorDetailActive: false,
        })
      } else {
        this.setState({
          indicatorDetailActive: true,
        })
      }
    }
  }

  render() {
    const rawData = this.props.data.allBaseDadosIndicadoresV23XlsxDb.edges

    /**
     *  Convert strapi data to default data structure
     */
    let data = []
    rawData.forEach(row => {
      exchangeData(row.node, data)
    })

    const textData = this.props.textData
    const content = textData.childDashboardContentJson

    let feedbackMessage = ""

    switch (this.state.originOfTooltipCall) {
      case "map":
        feedbackMessage = content.feedbackMessageMapDashboard
        break
      default:
        feedbackMessage = content.feedbackMessagePanelButtons
    }

    //lists all possible values for macro categories

    // const macroCategoryList = _.chain(data)
    //   .map(d => d.node["industriasCriativasMacro"])
    //   .uniq()
    //   .value()

    const macroCategoryList = flow(
      map(d => d.node["industriasCriativasMacro"]),
      uniq
    )(data)

    return (
      <>
        <MobileOnlyView>
          <div
            id="obcrei-dashboard"
            className="obcrei-dashboard"
            style={{
              marginTop: 60,
              //paddingTop: 50,
              //paddingBottom: 50,
              backgroundColor: "#393e46",
              position: "relative",
            }}>
            <div
              className="obcrei-container"
              style={{
                display: "flex",
                height: 300,
                justifyContent: "center",
                alignItems: "center",
              }}>
              <h3
                className="col col-sm-10 col-xs-12"
                style={{ color: "#FFFFFF", margin: 0, textAlign: "center" }}>
                {content.feedbackMobileView}
              </h3>
            </div>
          </div>
        </MobileOnlyView>
        <BrowserView>
          <div
            id="obcrei-dashboard"
            className="obcrei-dashboard"
            style={{
              //marginTop: 60,
              //paddingTop: 50,
              //paddingBottom: 50,
              backgroundColor: "#393e46",
              position: "relative",
            }}>
            {this.state.introOverlayActive ? (
              <SimpleTextContentOverlay
                setIntroLayerOverlayFalse={this.setIntroLayerOverlayFalse}
                textData={textData}
              />
            ) : null}
            <div className="obcrei-container">
              <div className="stepper-overlay">
                {this.state.stepperOverlayActive ? (
                  <StepperOverlay
                    toggleStepperOverlayActive={this.toggleStepperOverlayActive}
                    textData={textData}
                  />
                ) : (
                  <div
                    className="stepper-overlay-button"
                    onClick={this.toggleStepperOverlayActive}>
                    <svg width="32px" height="32px">
                      <circle fill="#1F1D30" cx="12" cy="12" r="12" />
                      <text
                        fontFamily="Montserrat-Regular, Montserrat"
                        fontSize="16"
                        fontWeight="normal"
                        letterSpacing="-0.28"
                        fill="#FFFFFF">
                        <tspan x="10" y="18">
                          i
                        </tspan>
                      </text>
                    </svg>
                  </div>
                )}
              </div>
              <div className="macro-menu-search-container">
                <MacroMenu
                  tagSelected={this.state.tagSelected}
                  macroCategoryList={macroCategoryList}
                  macroCategoryActive={this.state.macroCategoryActive}
                  toggleMacroMenuIsOpen={this.toggleMacroMenuIsOpen}
                  macroMenuIsOpen={this.state.macroMenuIsOpen}
                  searchIsActive={this.state.searchIsActive}
                  toggleSearchIsActive={this.toggleSearchIsActive}
                  data={data}
                  setIndicatorSelected={this.setIndicatorSelected}
                  changeTagSelected={this.changeTagSelected}
                  setStatisticsPanelIsOpen={this.setStatisticsPanelIsOpen}
                  macroMenuButtonText={content.macroMenuButton}
                />
              </div>
              <div className="circular-menu-container">
                <CircularMenu
                  circularMenuIsOpen={this.state.circularMenuIsOpen}
                  toggleCircularMenuIsOpen={this.toggleCircularMenuIsOpen}
                  dimensionSelected={this.state.dimensionSelected}
                  creativeCycleSelected={this.state.creativeCycleSelected}
                  setCreativeCycleSelected={this.setCreativeCycleSelected}
                  setDimensionSelected={this.setDimensionSelected}
                  data={data}
                  tagSelected={this.state.tagSelected}
                  indicatorGroupSelected={this.state.indicatorGroupSelected}
                  circularMenuButton={content.circularMenuButton}
                  feedbackMessagePanelButtons={
                    content.feedbackMessagePanelButtons
                  }
                  hoverMenuButtons={this.hoverMenuButtons.bind(this)}
                  isMouseTooltipVisible={this.state.isMouseTooltipVisible}
                />
              </div>
              <div className="graph-container">
                <GraphContainer
                  data={data}
                  changeTagSelected={this.changeTagSelected}
                  tagSelected={this.state.tagSelected}
                  setMacroCategoryActive={this.setMacroCategoryActive}
                  macroCategoryActive={this.state.macroCategoryActive}
                  toggleMacroMenuIsOpen={this.toggleMacroMenuIsOpen}
                  toggleStatisticsPanelIsOpen={this.toggleStatisticsPanelIsOpen}
                  toggleMapPanelIsOpen={this.toggleMapPanelIsOpen}
                  setStatisticsPanelIsOpen={this.setStatisticsPanelIsOpen}
                  isMouseTooltipVisible={this.state.isMouseTooltipVisible}
                  mapPanelIsOpen={this.state.mapPanelIsOpen}
                />
              </div>

              <div className="statistics-wrapper">
                {this.state.statisticsPanelIsOpen && this.state.tagSelected ? (
                  <StatisticsPanel
                    data={data}
                    tagSelected={this.state.tagSelected}
                    setYearSelected={this.setYearSelected}
                    yearSelected={this.state.yearSelected}
                    regionSelected={this.state.regionSelected}
                    setIndicatorSelected={this.setIndicatorSelected}
                    indicatorSelected={this.state.indicatorSelected}
                    toggleIndicatorDetailActive={
                      this.toggleIndicatorDetailActive
                    }
                    indicatorDetailActive={this.state.indicatorDetailActive}
                    toggleStatisticsPanelIsOpen={
                      this.toggleStatisticsPanelIsOpen
                    }
                    indicatorGroupSelected={this.state.indicatorGroupSelected}
                    setIndicatorGroupSelected={this.setIndicatorGroupSelected}
                    dimensionSelected={this.state.dimensionSelected}
                    creativeCycleSelected={this.state.creativeCycleSelected}
                    circularMenuIsOpen={this.state.circularMenuIsOpen}
                    toggleCircularMenuIsOpen={this.toggleCircularMenuIsOpen}
                    textData={content}
                    yearsAvailableNUTS2={this.state.yearsAvailableNUTS2}
                  />
                ) : (
                  <div className="statistics-menu-container">
                    <button
                      onMouseEnter={() => this.hoverMenuButtons()}
                      onMouseLeave={() => this.hoverMenuButtons()}
                      className="menu-button"
                      onClick={() =>
                        this.toggleStatisticsPanelIsOpen() &&
                        this.state.tagSelected
                      }>
                      {content.statisticsPanelButton}
                    </button>
                  </div>
                )}
              </div>
              <div className="map-wrapper">
                {this.state.mapPanelIsOpen && this.state.tagSelected ? (
                  <MapContainer
                    toggleMapPanelIsOpen={this.toggleMapPanelIsOpen}
                    data={data}
                    tagSelected={this.state.tagSelected}
                    setYearSelected={this.setYearSelected}
                    yearSelected={this.state.yearSelected}
                    regionSelected={this.state.regionSelected}
                    setIndicatorSelected={this.setIndicatorSelected}
                    indicatorSelected={this.state.indicatorSelected}
                    toggleIndicatorDetailActive={
                      this.toggleIndicatorDetailActive
                    }
                    indicatorDetailActive={this.state.indicatorDetailActive}
                    toggleStatisticsPanelIsOpen={
                      this.toggleStatisticsPanelIsOpen
                    }
                    indicatorGroupSelected={this.state.indicatorGroupSelected}
                    setIndicatorGroupSelected={this.setIndicatorGroupSelected}
                    dimensionSelected={this.state.dimensionSelected}
                    creativeCycleSelected={this.state.creativeCycleSelected}
                    setRegionSelected={this.setRegionSelected}
                    isMouseTooltipVisible={this.state.isMouseTooltipVisible}
                    setMouseTooltipNotVisible={this.setMouseTooltipNotVisible.bind(
                      this
                    )}
                    setMouseTooltipVisible={this.setMouseTooltipVisible.bind(
                      this
                    )}
                    setYearsAvailabelNUTS2={this.setYearsAvailabelNUTS2}
                  />
                ) : (
                  <div className="map-button-container">
                    <button
                      onMouseEnter={() => this.hoverMenuButtons()}
                      onMouseLeave={() => this.hoverMenuButtons()}
                      className="menu-button"
                      onClick={() =>
                        this.toggleMapPanelIsOpen() && this.state.tagSelected
                      }>
                      {content.mapButton}
                    </button>
                  </div>
                )}
                <MouseTooltip
                  className="typo-captions-regular feedback-tooltip"
                  visible={this.state.isMouseTooltipVisible}
                  offsetX={10}
                  offsetY={10}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: feedbackMessage,
                    }}
                  />
                </MouseTooltip>
              </div>
            </div>
          </div>
        </BrowserView>
      </>
    )
  }
}

export default RootDashboardComponent
