import React, { Component } from "react"
import BarChart from "./BarChart"
import _ from "lodash"
import "./bar-chart-container.scss"
import "../../../../helpers/styles/typography.scss"
import "./BarChartDescription"
import BarChartDescription from "./BarChartDescription"
import { isNullOrUndefined } from "util"
import Select, { components } from "react-select"

import * as d3 from "d3"

import {
  datavisColors,
  datavisDefaultColor,
} from "../../../../helpers/obcrei-helpers"
import { color } from "d3"

//TODO test with larger dataset fed to bar chart...more years available

export default class BarChartContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      regionSelectedOrdered: [],
      yearOptions: [],
      yearSelected: null,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let yearsAvailableNUTS2 = []

    let filteredDataByTag = nextProps.data.filter(
      row => row.node.tag1 === nextProps.tagSelected
    )

    let filteredData = nextProps.data.filter(
      row =>
        row.node.tag1 === nextProps.tagSelected &&
        nextProps.regionSelected.includes(row.node.escalaMicro)
    )

    filteredDataByTag.map(row => {
      if (row.node.escalaMicro !== "Portugal")
        yearsAvailableNUTS2.push(row.node.temporalidadeAno)
    })

    yearsAvailableNUTS2 = _.uniq(yearsAvailableNUTS2)

    if (nextProps.indicatorGroupSelected) {
      filteredData = filteredData.filter(
        row => row.node.tagGroup === nextProps.indicatorGroupSelected
      )
    }

    if (nextProps.indicatorSelected) {
      filteredData = filteredData.filter(
        row => row.node.indicador === nextProps.indicatorSelected
      )
    }

    if (nextProps.dimensionSelected) {
      filteredData = filteredData.filter(
        row => row.node.dimensao === nextProps.dimensionSelected
      )
    }

    if (nextProps.creativeCycleSelected) {
      filteredData = filteredData.filter(
        row =>
          row.node.cicloCriativo ===
          nextProps.creativeCycleSelected.replace(" ", "/")
      )
    }

    let rawData = []
    filteredData.forEach(node => {
      const row = node.node
      if (isNullOrUndefined(rawData[row.escalaMicro])) {
        rawData[row.escalaMicro] = {
          yearValues: [],
          indicador: row.indicador,
          descricao: row.descricao,
          fonte: row.fonte,
          estudo: row.estudo,
          escalaMicro: row.escalaMicro,
          years: [],
        }
      }

      if (yearsAvailableNUTS2.includes(row.temporalidadeAno))
        rawData[row.escalaMicro].yearValues[row.temporalidadeAno] = {
          year: row.temporalidadeAno,
          value: row.valor,
        }
    })

    rawData = Object.keys(rawData)
      .map(i => rawData[i])
      .map(row => {
        row.yearValues = Object.keys(row.yearValues).map(i => row.yearValues[i])
        return row
      })

    let orderedRegions = []

    if (rawData && rawData[0])
      if (nextProps.regionSelected[0] === rawData[0].escalaMicro) {
        orderedRegions = nextProps.regionSelected
      } else {
        orderedRegions = [
          nextProps.regionSelected[0],
          nextProps.regionSelected[1],
        ]
      }

    let yearOptions = []

    if (rawData && rawData[0]) {
      rawData[0].yearValues.map(d =>
        yearOptions.push({ value: d.year, label: d.year.toString() })
      )
    }

    //---testing data----

    // yearOptions = [
    //   { label: "2015", value: 2015 },
    //   { label: "2016", value: 2016 },
    //   { label: "2017", value: 2017 },
    //   { label: "2018", value: 2018 },
    //   { label: "2019", value: 2019 },
    // ]

    //----

    //years to display in graph. Maximum 4 years

    const last4YearsAvailableInitial = yearOptions.slice(-4)
    const last4YearsAvailableCurrent =
      prevState.yearSelected && prevState.yearSelected.slice(-4)

    const yearSelected = !prevState.yearSelected
      ? yearOptions.length > 4
        ? last4YearsAvailableInitial
        : yearOptions
      : prevState.yearSelected.length > 4
      ? last4YearsAvailableCurrent
      : prevState.yearSelected

    const colors = datavisColors
    const defaultColor = datavisDefaultColor

    rawData &&
      rawData[0] &&
      last4YearsAvailableInitial.map((d, i) => {
        rawData[0].yearValues.map(row => {
          if (yearOptions.length <= 4) {
            if (row.year === d.value) {
              row.color = colors[i]
            }
          } else {
            if (row.year === d.value) {
              row.color = colors[i]
            } else if (row.year !== d.value) {
              if (!row.color) row.color = defaultColor
            }
          }
        })
      })

    rawData &&
      rawData[1] &&
      last4YearsAvailableInitial.map((d, i) => {
        rawData[1].yearValues.map(row => {
          if (yearOptions.length <= 4) {
            if (row.year === d.value) {
              row.color = colors[i]
            }
          } else {
            if (row.year === d.value) {
              row.color = colors[i]
            } else if (row.year !== d.value) {
              if (!row.color) row.color = defaultColor
            }
          }
        })
      })

    let yMaxFirstChart = d3.max(rawData[0].yearValues, d => d.value)
    let yMaxSecondChart = null

    if (rawData[1])
      yMaxSecondChart = d3.max(rawData[1].yearValues, d => d.value)

    let yMax = Math.max(yMaxFirstChart, yMaxSecondChart)

    return {
      data: rawData,
      yMax: yMax,
      regionSelectedOrdered: orderedRegions,
      yearOptions: yearOptions,
      yearSelected: yearSelected,
      last4Years: !prevState.yearSelected
        ? last4YearsAvailableInitial
        : last4YearsAvailableCurrent,
    }
  }

  setYears = years => {
    if (years && years.length > 0) {
      let orderedYears = _.sortBy(years, d => d.value)

      this.setState({
        yearSelected: orderedYears,
      })
    } else {
      return
    }
  }

  render() {
    const yearOptionsDefaultValue = []

    this.state.yearOptions.map(d => yearOptionsDefaultValue.push(d))

    //styles for <Select />

    const customStyles = {
      option: (provided, state) => {
        return {
          ...provided,
          paddingLeft: "21px",
          color: "#060606",
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "#F6EDF7",
            cursor: "pointer",
          },
        }
      },
      control: (provided, state) => ({
        ...provided,
        backgroundColor: "transparent",
        border: "none",

        border: state.isFocused ? 0 : 0,
        // This line disable the blue border
        boxShadow: state.isFocused ? 0 : 0,
        "&:hover": {
          border: state.isFocused ? 0 : 0,
        },
        minHeight: 0,
      }),
      indicatorsContainer: provided => ({
        ...provided,
        display: "red",
      }),
      indicatorSeparator: provided => ({
        display: "none",
      }),
      clearIndicator: provided => ({
        //display: "none",
      }),
      menu: provided => ({
        ...provided,
        backgroundColor: "transparent",
      }),
      menuList: provided => ({
        ...provided,
        backgroundColor: "rgba(214,212,227,.95)",
        borderRadius: "10px",
      }),
      multiValueLabel: provided => ({
        ...provided,
        color: "white",
        paddingTop: 0,
        paddingBottom: 0,
      }),
      multiValueRemove: provided => ({
        ...provided,
        color: "white",
        paddingLeft: 0,
        "&:hover": {
          color: "#ff3e25",
          backgroundColor: "transparent",
        },
      }),
      multiValue: provided => ({
        ...provided,
        backgroundColor: "#d6d4e3",
        borderRadius: "6px",
      }),
      dropdownIndicator: provided => ({
        ...provided,
        display: "none",
        border: "none",
      }),
    }

    return (
      <div className="bar-chart-wrapper">
        <div
          className="back-button typo-body-2-chart"
          onClick={this.props.toggleIndicatorDetailActive}>
          Voltar
        </div>
        <div className="bar-chart-title typo-chart-title">
          {this.state.data[0] && this.state.data[0].indicador}
        </div>
        {this.props.regionSelected.length === 1 ? (
          <>
            <div className="bar-chart-title typo-chart-title-semi bar-chart-location">
              {this.props.regionSelected[0]}
            </div>
            <div className="select-years-bar filter-map-select">
              <Select
                options={this.state.yearOptions}
                defaultValue={yearOptionsDefaultValue}
                value={this.state.yearSelected}
                onChange={value => this.setYears(value)}
                styles={customStyles}
                className={"typo-body-2-chart"}
                isClearable={false}
                isSearchable={false}
                components={{ ValueContainer }}
                //menuIsOpen={true}
                isMulti={true}
              />
            </div>
            <div className="bar-chart-main-content">
              {this.state.data[0] && (
                <BarChart
                  data={this.state.data[0].yearValues}
                  yearSelected={this.props.yearSelected}
                  setYearSelected={this.props.setYearSelected}
                  yearsToDisplay={this.state.yearSelected}
                  yMax={this.state.yMax}
                />
              )}
              <BarChartDescription
                description={
                  this.state.data[0] ? this.state.data[0].descricao : ""
                }
                sources={this.state.data[0] ? this.state.data[0].fonte : ""}
                study={this.state.data[0] ? this.state.data[0].estudo : ""}
              />
            </div>
          </>
        ) : (
          <>
            <div className="bar-chart-main-content">
              <div className="bar-chart-main-content-first">
                <div className="bar-chart-title typo-chart-title-semi bar-chart-location bar-chart-location-first">
                  {this.state.regionSelectedOrdered[0]}
                </div>
                <div className="select-years-bar filter-map-select">
                  <Select
                    options={this.state.yearOptions}
                    defaultValue={yearOptionsDefaultValue}
                    value={this.state.yearSelected}
                    onChange={value => this.setYears(value)}
                    styles={customStyles}
                    className={"typo-body-2-chart"}
                    isClearable={false}
                    isSearchable={false}
                    components={{ ValueContainer }}
                    //menuIsOpen={true}
                    isMulti={true}
                  />
                </div>
                {this.state.data[0] && (
                  <BarChart
                    data={this.state.data[0].yearValues}
                    yearSelected={this.props.yearSelected}
                    setYearSelected={this.props.setYearSelected}
                    yearsToDisplay={this.state.yearSelected}
                    yMax={this.state.yMax}
                  />
                )}
              </div>
              <div className="bar-chart-divider" />
              <div className="bar-chart-main-content-second">
                <div
                  className="bar-chart-title typo-chart-title-semi bar-chart-location bar-chart-location-second"
                  style={{ marginBottom: 24 }}>
                  {this.state.regionSelectedOrdered[1]}
                </div>
                {this.state.data[1] && this.state.yMax && (
                  <BarChart
                    data={this.state.data[1].yearValues}
                    yearSelected={this.props.yearSelected}
                    setYearSelected={this.props.setYearSelected}
                    yearsToDisplay={this.state.yearSelected}
                    yMax={this.state.yMax}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

const ValueContainer = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props} className="bar-value-container">
        <div className="bar-select-arrow">
          <svg width="6px" height="4px">
            <g transform="translate(-45.000000, -441.000000)" fill="#D6D4E3">
              <polygon points="45 441 51 441 48 445" />
            </g>
          </svg>
        </div>
        <div className="bar-select-value">{children}</div>
      </components.ValueContainer>
    )
  )
}
