import React, { Component } from "react"
import DotPlot from "./DotPlot"
//import _ from "lodash"
import "./dot-plot-container.scss"
import { isNullOrUndefined } from "util"
import _, { filter } from "lodash"

export default class DotPlotContainer extends Component {
  state = {
    data: [],
    dotPlotInfoOpen: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var yearsAvailableNUTS2 = []

    let filteredData = nextProps.data.filter(
      row =>
        row.node.tag1 === nextProps.tagSelected &&
        nextProps.regionSelected.includes(row.node.escalaMicro.trim())
    )

    let filteredDataByTag = nextProps.data.filter(
      row => row.node.tag1 === nextProps.tagSelected
    )

    filteredDataByTag.map(row => {
      if (row.node.escalaMicro !== "Portugal")
        yearsAvailableNUTS2.push(row.node.temporalidadeAno)
    })

    yearsAvailableNUTS2 = _.uniq(yearsAvailableNUTS2)

    if (nextProps.indicatorGroupSelected) {
      filteredData = filteredData.filter(
        row => row.node.tagGroup === nextProps.indicatorGroupSelected
      )
    } else {
      filteredData = filteredData.filter(row => {
        return row.node.tagGroup === 1
      })
    }

    if (nextProps.dimensionSelected) {
      filteredData = filteredData.filter(
        row =>
          row.node.dimensao === nextProps.dimensionSelected.replace(" ", "/")
      )
    }

    if (nextProps.creativeCycleSelected) {
      filteredData = filteredData.filter(
        row =>
          row.node.cicloCriativo ===
          nextProps.creativeCycleSelected.replace(" ", "/")
      )
    }

    let rawData = []

    filteredData.forEach(node => {
      const row = node.node

      if (isNullOrUndefined(rawData[row.indicador])) {
        rawData[row.indicador] = []

        rawData[row.indicador]["indicador"] = row.indicador
      }

      rawData[row.indicador][row.temporalidadeAno] = row.valor
    })

    let limitYears = 5

    //last 5 years available NUT2
    if (yearsAvailableNUTS2.length > limitYears) {
      yearsAvailableNUTS2 = yearsAvailableNUTS2.sort().slice(-limitYears)
    }

    return {
      data: Object.keys(rawData).map(i => {
        Object.keys(rawData[i]).map(key => {
          if (key !== "indicador") {
            if (!yearsAvailableNUTS2.includes(key)) {
              delete rawData[i][key]
            }
          }
        })

        return rawData[i]
      }),
    }
  }

  componentDidMount() {}

  toggleDotPlotInfoOpen() {
    this.setState({
      dotPlotInfoOpen: !this.state.dotPlotInfoOpen,
    })
  }
  render() {
    return (
      <div className="dot-plot-wrapper">
        <div className="dot-plot-content-wrapper">
          {this.state.dotPlotInfoOpen ? (
            <div
              className="dot-plot-header"
              onClick={() => this.toggleDotPlotInfoOpen()}>
              <svg width="22px" height="22px" viewBox="0 0 22 22">
                <circle
                  fill="#1E1B2F"
                  fillRule="evenodd"
                  cx="11"
                  cy="11"
                  r="11"
                />
                <text
                  fontFamily="OpenSans-Bold, Open Sans"
                  fontSize="14"
                  fontWeight="bold"
                  letterSpacing="-0.21"
                  fill="#FFFFFF">
                  <tspan x="7" y="16">
                    X
                  </tspan>
                </text>
              </svg>
            </div>
          ) : (
            <div className="dot-plot-header" style={{ height: 22 }}></div>
          )}
          <div className="dot-plot-title typo-chart-title">
            {this.state.dotPlotInfoOpen
              ? this.props.infoDotPlotTitle
              : this.props.regionSelected[1]
              ? this.props.regionSelected[1]
              : this.props.regionSelected[0]}
          </div>
          {this.state.dotPlotInfoOpen ? (
            <p className="typo-body-2-chart" style={{ marginLeft: "21px" }}>
              {this.props.infoDotPlotDetail}
            </p>
          ) : (
            <DotPlot
              data={this.state.data}
              setIndicatorSelected={this.props.setIndicatorSelected}
              toggleIndicatorDetailActive={
                this.props.toggleIndicatorDetailActive
              }
              indicatorSelected={this.props.indicatorSelected}
              yearSelected={this.props.yearSelected}
              setYearSelected={this.props.setYearSelected}
              toggleCircularMenuIsOpen={this.props.toggleCircularMenuIsOpen}
            />
          )}
          {!this.state.dotPlotInfoOpen && (
            <div
              className="overlay-button"
              onClick={() => this.toggleDotPlotInfoOpen()}>
              <svg width="32px" height="32px">
                <circle fill="#393e46" cx="12" cy="12" r="12" />
                <text
                  fontFamily="Montserrat-Regular, Montserrat"
                  fontSize="16"
                  fontWeight="normal"
                  letterSpacing="-0.28"
                  fill="#FFFFFF">
                  <tspan x="10" y="18">
                    i
                  </tspan>
                </text>
              </svg>
            </div>
          )}
        </div>
      </div>
    )
  }
}
