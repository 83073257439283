const setNodeColor = macroCategory => {
  let color = ""
  switch (macroCategory) {
    case "Média":
      color = "#F27F70"
      break
    case "Criações funcionais":
      color = "#FFC347"
      break
    case "Artes":
      color = "#03D7C6"
      break
    case "Património":
      color = "#E019EB"
      break
    case "Práticas criativas e culturais em geral":
      color = "#BDECFF"
      break
    default:
      color = "white"
      break
  }

  return color
}

const datavisColors = ["#00A5E0", "#FFEF07", "#F0387E", "#137547"]
const datavisDefaultColor = "#F0F0F0"

export { setNodeColor, datavisColors, datavisDefaultColor }
